import { configureStore } from '@reduxjs/toolkit';
import oqaatReducer from '~app/pages/SurveyTaking/v2/slices/oqaatSlice';
import errorsReducer from '~app/pages/SurveyTaking/v2/slices/errorsSlice';
import surveyReducer from '~app/pages/SurveyTaking/v2/slices/surveySlice';

export const store = configureStore({
  reducer: {
    oqaatState: oqaatReducer,
    errorsState: errorsReducer,
    surveyState: surveyReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
