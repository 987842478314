import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

const useSurveyStyles = createSurveyStyles(theme => ({
  pageDescription: {
    color: theme.questionColor,
    lineHeight: 1.25,
    paddingBottom: 15,
    ...theme?.questionTitle,
    // reset font weight changed by the theme above
    '& strong': {
      fontWeight: 'bold !important',
    },
  },
}));

export default useSurveyStyles;
