import unescape from 'lodash-es/unescape';

/**
 * Decodes HTML entities in a string.
 * eg. 'hello &#931; &#927; &#937; &#128522;' becomes 'hello Σ Ο Ω 😊'
 * @param str The string to be decoded
 * @returns The decoded string
 */
export const decodeHtmlEntities = (str?: string): string | undefined => {
  if (!str) return str;
  return unescape(str).replace(/&#(\d+);/g, (_, dec) => String.fromCodePoint(dec));
};
export default decodeHtmlEntities;
