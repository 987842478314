/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { setFocusToView } from '~app/components/Survey/SurveyFormat';
import { RootState, AppDispatch } from '~app/storeV2';

// ========== INITIAL STATE

export type OQAATState = {
  /**
   * The currently registered views (eg. questions, page navigation, page description).
   * Used for determining the currently active view, setting the next view active, scrolling to a view, etc.
   *  */
  views: string[];
  /** The currently active view - triggers style changes and affects navigation */
  active: string | null;
  /**
   * Whether there is currently a scroll animation occurring.
   * Used in ActiveViewScrollManager to prevent multiple scroll animations from occurring at the same time, as well as preventing unnecessary calculations on scroll.
   *  */
  isAnimating: boolean;
};

export const initialState: OQAATState = {
  views: [],
  active: null,
  isAnimating: false,
};

// ========== SLICE

const sliceName = 'OQAAT';

export const oqaatSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    register: (state, action: PayloadAction<string>) => {
      state.views.push(action.payload);
    },
    unregister: (state, action: PayloadAction<string>) => {
      state.views = state.views.filter(v => v !== action.payload);
    },
    setActive: (state, { payload: name }: PayloadAction<string | null>) => {
      if (name !== null) {
        state.active = state.views.find(v => v === name) ?? null;
      }
    },
    setAnimating: (state, action: PayloadAction<boolean>) => {
      state.isAnimating = action.payload;
    },
    reset: () => initialState,
  },
});

// ========== ACTIONS

export const { register, unregister, setActive, setAnimating, reset } = oqaatSlice.actions;

// ========== THUNKS
// The built-in createAsyncThunk() function provides access to the state and dispatch actions via thunkAPI parameter - useful for writing reducers that cause side-effects (eg. dispatching other actions) which wouldn't work correctly in a regular reducer.
// Thunks are typically used for async operations: https://redux-toolkit.js.org/api/createAsyncThunk

export const activateView = createAsyncThunk(`${sliceName}/activateView`, (name: string | null, thunkAPI) => {
  const dispatch = thunkAPI.dispatch as AppDispatch;

  dispatch(setActive(name));
  if (name) setFocusToView(name);
});

export const setNextActive = createAsyncThunk(`${sliceName}/setNextActive`, (_, thunkAPI): void => {
  const { oqaatState } = thunkAPI.getState() as RootState;
  const activeIndex = oqaatState.views.findIndex(v => v === oqaatState.active);
  const nextActive = oqaatState.views[(activeIndex as number) + 1] || null;
  if (nextActive) {
    setFocusToView(nextActive);
  }
});

// ========== SELECTORS

/** The survey format state object */
const selectOQAATState = (state: RootState): OQAATState => state.oqaatState;

/** The currently registered views (eg. questions, page navigation, page description). */
export const selectViews = createSelector(selectOQAATState, oqaatState => oqaatState.views);

/** The currently active view - triggers style changes and affects navigation */
export const selectActive = createSelector(selectOQAATState, oqaatState => oqaatState.active);

/** Whether there is currently a scroll animation occurring. */
export const selectIsAnimating = createSelector(selectOQAATState, oqaatState => oqaatState.isAnimating);

// ========== EXPORT

export { setFocusToView };

export default oqaatSlice.reducer;
