import React from 'react';
import classNames from 'classnames';

import useStyles from './useStyles';

export type SurveyLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  /** Required href */
  href: string;
  /** Changes behaviour of the textDecoration for hover/focus. */
  underline?: 'always' | 'never' | 'inverse' | 'auto';
  /** Link text color */
  textColor?: string;
};

/**
 * A Survey-Styled Link
 */
function SurveyLink({
  className,
  underline = 'auto',
  textColor = '',
  children,
  ...props
}: SurveyLinkProps): React.ReactElement {
  const styles = useStyles({ underline, textColor });
  return (
    <a className={classNames(styles.surveyAnchor, className)} {...props}>
      {children}
    </a>
  );
}

export default SurveyLink;
