import { ExternalQuestion } from '@sm/question-widgets/respondent-survey';

/**
 * Extracts the random-assignment variableID
 * @param question question with assignments
 * @returns `variable_id` of the chosen question text (Text A/B) or question image (Image A/B)
 * or `null` if it was not founds
 */
export const mapImplicitResponse = (question: ExternalQuestion): string | null => {
  const assignments = question?.heading.assignments;

  if (!question || !assignments || assignments.length === 0) {
    return null;
  }

  if (question.variant === 'IMAGE') {
    const currentAssignment = assignments.find(
      ({ heading: { image } }) => image?.id === question.heading.default.image?.id
    );
    return currentAssignment?.id ?? null;
  }

  return assignments.find(({ heading: { text } }) => text === question.heading.default.text)?.id ?? null;
};

export default mapImplicitResponse;
