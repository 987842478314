import React, { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import { T, L10NContext, defineMessages } from '@sm/intl';
import { SurveyButton } from '~app/components/Survey/SurveyButton';
import { decodeHtmlEntities } from '~app/pages/SurveyTaking/helper/decodeHtmlEntities';
import useStyles from './useStyles';

const COPY = defineMessages({
  PREVIOUS: {
    id: 'SurveyTaking.PageNavigation.Previous',
    defaultMessage: 'Previous',
    description: `[Type: button][Vis: high] - Allows the respondent to go back to the previous page to review or edit their response.`,
  },
  NEXT: {
    id: 'SurveyTaking.PageNavigation.Next',
    defaultMessage: 'Next',
    description: `[Type: button][Vis: high] - Saves the responses for that page in the Analyze Results section and proceeds to the next page in the survey.`,
  },
  DONE: {
    id: 'SurveyTaking.PageNavigation.Done',
    defaultMessage: 'Done',
    description: `[Type: button][Vis: high] - Submits the survey and saves the response as Complete in the Analyze Results section.`,
  },
});

export type Props = {
  /** Custom labels for Next, Previous, and Done buttons */
  labels?: {
    previous?: string;
    next?: string;
    done?: string;
  };
  /** The current page - starting at 1 */
  currentPage: number;
  /** The total number of pages */
  totalPages: number;
  /** Whether the Done button should be disabled */
  disableSubmit: boolean;
  /** should previous button be shown */
  showPrevious?: boolean;
  /** Callback for when the user clicks the Next button - overrides redux */
  onNext?(): void;
  /** Callback for when the user clicks the Previous button - overrides redux */
  onPrevious?(): void;
  /** Callback for when the user clicks the Done button */
  onDone(): void;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Determine if a translation attribute applies if a user-generated label
 * is not provided
 * @param label - A user-generated label
 * @param defaultLabel - a default label
 * @returns {Object} and object with a label and translation details
 */
function getButtonDetails(
  label?: React.ReactNode,
  defaultLabel?: React.ReactNode
): { label?: React.ReactNode; translate?: 'yes' | 'no' } {
  return { label: label ?? defaultLabel, translate: label ? 'no' : undefined };
}

const PageNavigation = (
  {
    labels,
    className,
    currentPage,
    totalPages = 1,
    onNext,
    onPrevious,
    onDone,
    disableSubmit,
    showPrevious = false,
  }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element => {
  const { isRTL } = useContext(L10NContext);
  const { pageNavigationContainer, navButtons } = useStyles({ isRTL });
  const prevButton = getButtonDetails(decodeHtmlEntities(labels?.previous), <T desc={COPY.PREVIOUS} />);
  const doneButton = getButtonDetails(decodeHtmlEntities(labels?.done), <T desc={COPY.DONE} />);
  const nextButton = getButtonDetails(decodeHtmlEntities(labels?.next), <T desc={COPY.NEXT} />);

  return (
    <div className={classNames(pageNavigationContainer, className)}>
      {currentPage > 1 && showPrevious && (
        <SurveyButton
          translate={prevButton.translate}
          className={navButtons}
          size="lg"
          onClick={() => {
            if (!disableSubmit && onPrevious) {
              onPrevious();
            }
          }}
          disabled={!!disableSubmit}
        >
          <>{prevButton.label}</>
        </SurveyButton>
      )}
      {currentPage === totalPages && (
        <SurveyButton
          translate={doneButton.translate}
          className={navButtons}
          size="lg"
          onClick={() => {
            if (!disableSubmit) {
              onDone();
            }
          }}
          disabled={!!disableSubmit}
          suppressHydrationWarning
        >
          <>{doneButton.label}</>
        </SurveyButton>
      )}
      {currentPage < totalPages && (
        <SurveyButton
          translate={nextButton.translate}
          className={navButtons}
          size="lg"
          onClick={() => {
            if (!disableSubmit && onNext) {
              onNext();
            }
          }}
          disabled={!!disableSubmit}
        >
          <>{nextButton.label}</>
        </SurveyButton>
      )}
    </div>
  );
};

export default forwardRef<HTMLDivElement, Props>(PageNavigation);
