import { SurveyErrorGroup } from './types';

export const ErrorType = {
  REDIRECT: 'REDIRECT' as const,
  FOUR_OH_FOUR: 'FOUR_OH_FOUR' as const,
  COLLECTOR_CLOSED: 'COLLECTOR_CLOSED' as const,
  INVALID_OPEN_ENDED_VALIDATION: 'INVALID_OPEN_ENDED_VALIDATION' as const,
  INVALID_SURVEY_VERSION: 'INVALID_SURVEY_VERSION' as const,
  MISSING_REQUIRED_FIELD: 'MISSING_REQUIRED_FIELD' as const,
  /** @deprecated */
  REQUEST_IP_DISALLOWED: 'REQUEST_IP_DISALLOWED' as const,
  SURVEY_CANNED: 'SURVEY_CANNED' as const,
  FAILED_VALIDATION: 'FAILED_VALIDATION' as const,
  INVALID_SURVEY_ID: 'INVALID_SURVEY_ID' as const,
  INVALID_RESPONSE_ID: 'INVALID_RESPONSE_ID' as const,
  INVALID_COLLECTOR_ID: 'INVALID_COLLECTOR_ID' as const,
  MISSING_OR_MALFORMED_SURVEY_ID: 'MISSING_OR_MALFORMED_SURVEY_ID' as const,
  MISSING_OR_MALFORMED_COLLECTOR_ID: 'MISSING_OR_MALFORMED_COLLECTOR_ID' as const,
  MISSING_OR_MALFORMED_RESPONSE_ID: 'MISSING_OR_MALFORMED_RESPONSE_ID' as const,
} as const;

export const defaultErrorGroups: SurveyErrorGroup = {
  redirect: [ErrorType.REDIRECT, ErrorType.COLLECTOR_CLOSED, ErrorType.REQUEST_IP_DISALLOWED],
  notFound: [ErrorType.FOUR_OH_FOUR],
  validation: [ErrorType.MISSING_REQUIRED_FIELD, ErrorType.INVALID_OPEN_ENDED_VALIDATION, ErrorType.FAILED_VALIDATION],
  canned: [ErrorType.SURVEY_CANNED],
  gone: [ErrorType.INVALID_SURVEY_ID, ErrorType.INVALID_RESPONSE_ID, ErrorType.INVALID_COLLECTOR_ID],
  badRequest: [
    ErrorType.MISSING_OR_MALFORMED_COLLECTOR_ID,
    ErrorType.MISSING_OR_MALFORMED_SURVEY_ID,
    ErrorType.MISSING_OR_MALFORMED_RESPONSE_ID,
  ],
  /**  Added so it's not caught by unkownErrors. which was making the 500 error display when not needed. */
  invalidSurveyVersion: [ErrorType.INVALID_SURVEY_VERSION],
};
