import React, { forwardRef, ReactNode } from 'react';
import { defineMessages, T } from '@sm/intl';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import useStyles from './useStyles';
import { ProgressBar } from '~app/components/core/ProgressBar';
import VisuallyHidden from '~app/components/core/VisuallyHidden';
import { useAppSelectorV2 as useAppSelector } from '~app/hooks';
import { selectTotalAnswers } from '~app/pages/SurveyTaking/v2/slices/surveySlice';

const COPY = defineMessages({
  ANSWERED_WITH_PROGRESS_SR_PREFIX: {
    id: 'Survey.PersistentProgressBar.AnsweredWithProgressSrPrefix',
    defaultMessage: '<span id="visuallyHiddenEl">Current Progress,</span> {current} of {total} answered',
    description:
      '[Type: Label][Vis: med] - Shows the total number of questions answered with a visually hidden, speech readout prefix ("visuallyHiddenEl") denoting question progress',
  },
});

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface Props {
  id?: string;
  /** The current value - overrides redux */
  value?: number;
  /** The current value in percentage */
  total: number;
  /** Prop to set Right-to-Left */
  isRTL?: boolean;
}

// No need for use memo since the only time the re-rendering happens is when the value change or theme
// Also very light calculation
const getCurrentValue = (value: number, total: number): number => {
  // Avoid division by 0
  if (total <= 0) {
    return 0;
  }
  // Return the percentage
  return (value / total) * 100;
};

/** Wrapper for @sm/intl react-based tokenization  */
const VisuallyHiddenEl = ({ children }: { children: ReactNode }): React.ReactElement => (
  <VisuallyHidden isHidden>{children}</VisuallyHidden>
);

function PersistentProgressBar(
  { id = 'persistent-progress', value, total, isRTL }: Props,
  ref: React.Ref<HTMLDivElement>
): React.ReactElement {
  const theme = useSurveyTheme();
  const { progressBarContainer, progressBar, text } = useStyles({ isRTL: !!isRTL });
  const totalAnswers = useAppSelector(selectTotalAnswers);
  const boundedCurrentValue = getCurrentValue(value ?? totalAnswers, total);
  const progressId = id;
  const progressLabelId = `${progressId}-label`;
  const progressBarId = `${progressId}-bar`;

  return (
    <div id={progressId} className={progressBarContainer} ref={ref}>
      <span id={progressLabelId} aria-live="polite" aria-atomic className={text}>
        <T
          desc={COPY.ANSWERED_WITH_PROGRESS_SR_PREFIX}
          values={{
            current: value ?? totalAnswers,
            total,
            visuallyHiddenEl: VisuallyHiddenEl,
          }}
        />
      </span>
      <ProgressBar
        id={progressBarId}
        aria-labelledby={progressLabelId}
        value={boundedCurrentValue}
        borderColor={theme.secondaryAccentColor}
        progressColor={theme.secondaryAccentColor}
        radius={5}
        height={8}
        className={progressBar}
      />
    </div>
  );
}

export default forwardRef<HTMLDivElement, Props>(PersistentProgressBar);
