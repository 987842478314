import React, { useContext, useMemo, ReactNode, ReactElement } from 'react';
import { defineMessages, T, t } from '@sm/intl';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import { LogoWithText } from '@sm/webassets/Logos';
import { StaticContext } from '@sm/webassets/StaticContext';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme';
import { SurveyLink } from '../SurveyLink';
import useStyles from './useStyles';
import getLinkBasedOnLangCode from '~app/helpers/languageLinks';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_LOCATION,
  ANALYTICS_INTERACTION_TYPE,
  FOOTER_ANALYTICS_LINK_TEXT,
  ANALYTICS_WEBTYPE_TYPE,
} from '~app/components/amplitudeEnums';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface FooterPoweredByLogoBlockProps {
  /** A boolean prop for grayscale themes. */
  isGrayScale: boolean;
  // Data for Amplitude Tracking events
  surveyId: string;
  collectorId: string | undefined;
  collectorType: string | undefined;
}

const COPY = defineMessages({
  POWERED_BY: {
    id: 'Survey.FooterPoweredByLogoBlock.PoweredBy',
    defaultMessage: 'Powered by',
    description: '[Type: Paragraph][Vis: high] - Powered by text',
  },
  CTA_UNDER_LOGO: {
    id: 'Survey.FooterPoweredByLogoBlock.CtaUnderLogo',
    defaultMessage: 'See how easy it is to <span id="surveyLinkEl">create surveys and forms</span>.',
    description: '[Type: Paragraph][Vis: high] - "See how easy it is to create a survey" footer-CTA under the logo',
  },
  LOGO: {
    id: 'Survey.FooterPoweredByLogoBlock.Logo',
    defaultMessage: 'Survey monkey logo',
    description: '[Type: Link][Vis: low] - Survey monkey logo alt text for link in image',
  },
});

function FooterPoweredByLogoBlock({
  isGrayScale,
  surveyId,
  collectorId,
  collectorType,
}: FooterPoweredByLogoBlockProps): ReactElement {
  const {
    environment: { domain, languageCode },
  } = useContext(StaticContext);

  const onLinkClick = (linkText: string): void => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: ANALYTICS_EVENTS.USER_INTERACTION,
        interactionType: ANALYTICS_INTERACTION_TYPE.CLICK,
        linkText,
        interactionLocation: ANALYTICS_LOCATION.SURVEY_FOOTER,
        surveyId,
        collectorId,
        collectorType,
        webType: ANALYTICS_WEBTYPE_TYPE.RESPWEB,
      },
    });
  };

  const POWERED_BY_SURVEY_MONKEY_LOGO_URL = getLinkBasedOnLangCode({
    languageCode,
    domain,
    path: '/?ut_source=survey_poweredby_home',
  });
  const CREATE_SURVEY_LINK_HOW_IT_WORKS = getLinkBasedOnLangCode({
    languageCode,
    domain,
    path: '/mp/take-a-tour/?ut_source=survey_poweredby_howitworks',
  });

  const surveyTheme = useSurveyTheme();
  const logoColor = useMemo(() => {
    const isDarkBackground = surveyTheme.isDark;
    if (isDarkBackground) {
      return isGrayScale ? 'rgba(255, 255, 255, 0.5)' : '#FFFFFF';
    }
    return isGrayScale ? 'rgba(0, 0, 0, 0.5)' : '';
  }, [isGrayScale, surveyTheme.isDark]);

  const textColor = useMemo(() => {
    return surveyTheme.isDark ? '#FFFFFF' : 'rgb(51, 62, 72)';
  }, [surveyTheme.isDark]);

  const { container, text, logoContainer, logo, imageLink } = useStyles({ surveyTheme, logoColor, textColor });

  /** Wrapper for @sm/intl react-based tokenization  */
  const SurveyLinkEl = ({ children }: { children: ReactNode }): ReactElement => (
    <SurveyLink
      onClick={() => onLinkClick(FOOTER_ANALYTICS_LINK_TEXT.CREATE_SURVEY_CLICK)}
      target="_blank"
      href={CREATE_SURVEY_LINK_HOW_IT_WORKS}
      rel="noreferrer"
      underline="always"
      textColor={textColor}
    >
      {children}
    </SurveyLink>
  );

  return (
    <div className={container}>
      <p className={text}>
        <T desc={COPY.POWERED_BY} />
      </p>
      <SurveyLink
        onClick={() => onLinkClick(FOOTER_ANALYTICS_LINK_TEXT.POWERED_BY_CLICK)}
        className={imageLink}
        rel="noreferrer"
        target="_blank"
        href={POWERED_BY_SURVEY_MONKEY_LOGO_URL}
        aria-label={t(COPY.LOGO)}
        data-testid="FooterPoweredByLogoBlock__Logo"
        underline="never"
      >
        <span role="presentation" aria-hidden="true" className={logoContainer}>
          <LogoWithText className={logo} />
        </span>
      </SurveyLink>
      <p className={text}>
        <T
          desc={COPY.CTA_UNDER_LOGO}
          values={{
            surveyLinkEl: SurveyLinkEl,
          }}
        />
      </p>
    </div>
  );
}

export default FooterPoweredByLogoBlock;
