import React from 'react';
import classNames from 'classnames';
import useStyles from './useStyles';

export type Props = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

/**
 * BypassBlock for accessible skip links
 * @example
 * ```ts
 * <BypassBlock className={optionalOverrideClasses}>
 *   <a href="#main-content">Skip to Content</a>
 * </BypassBlock>
 * // ...
 * <main id="main-content" />
 * ```
 */
function BypassBlock({ children, className, ...restProps }: Props): React.ReactElement {
  const { skipLinks } = useStyles();

  return (
    <div className={classNames(skipLinks, className)} {...restProps}>
      {children}
    </div>
  );
}

export default BypassBlock;
