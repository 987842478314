import { SurveyTheme as SurveyThemeType } from '@sm/webassets/SurveyTheme/types';

type LayoutOptionType = Required<SurveyThemeType['layout']>;
type LayoutName = LayoutOptionType | '';
export const isPartial = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('PARTIAL');
};
export const isCenter = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('CENTER');
};
export const isLeft = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('LEFT');
};
export const isRight = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('RIGHT');
};
export const isLeftOrRight = (layoutName: LayoutName = ''): boolean => {
  return isLeft(layoutName) || isRight(layoutName);
};
export const isPartialSide = (layoutName: LayoutName = ''): boolean => {
  return isPartial(layoutName) && isLeftOrRight(layoutName);
};
export const isPartialLeft = (layoutName: LayoutName = ''): boolean => {
  return isPartial(layoutName) && isLeft(layoutName);
};
export const isPartialRight = (layoutName: LayoutName = ''): boolean => {
  return isPartial(layoutName) && isRight(layoutName);
};
export const isPartialHalf = (layoutName: LayoutName = ''): boolean => {
  return isPartial(layoutName) && layoutName.includes('HALF');
};
export const isPartialThird = (layoutName: LayoutName = ''): boolean => {
  return isPartial(layoutName) && layoutName.includes('THIRD');
};
export const isBanner = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('BANNER');
};
export const isFull = (layoutName: LayoutName = ''): boolean => {
  return layoutName.includes('FULL');
};
export const isFullLeftOrRight = (layoutName: LayoutName = ''): boolean => {
  return isFull(layoutName) && !isCenter(layoutName);
};

export const getThemeLayoutType = (layoutName: LayoutName = ''): Record<string, boolean> => {
  return {
    isPartial: isPartial(layoutName),
    isCenter: isCenter(layoutName),
    isLeft: isLeft(layoutName),
    isRight: isRight(layoutName),
    isLeftOrRight: isLeftOrRight(layoutName),
    isPartialSide: isPartialSide(layoutName),
    isPartialLeft: isPartialLeft(layoutName),
    isPartialRight: isPartialRight(layoutName),
    isPartialHalf: isPartialHalf(layoutName),
    isPartialThird: isPartialThird(layoutName),
    isBanner: isBanner(layoutName),
    isFull: isFull(layoutName),
  };
};

// Note: Refactor for cleaner code.
export type LayoutSizeName = 'full' | 'fullLeft' | 'fullRight' | 'half' | 'third' | 'top';
export const getLayoutSize = (layoutName: LayoutName = ''): LayoutSizeName => {
  const lo = getThemeLayoutType(layoutName);
  let size: LayoutSizeName = 'full';
  if (lo.isFull && lo.isLeftOrRight) {
    if (lo.isLeft) {
      size += 'Left';
    } else {
      size += 'Right';
    }
  } else if (lo.isPartialSide && !lo.isPartialThird) {
    size = 'half';
  } else if (lo.isPartialThird) {
    size = 'third';
  } else if (lo.isBanner) {
    size = 'top';
  }
  return size as LayoutSizeName;
};
