import React, { ReactNode, ReactElement } from 'react';
import useStyles from './useStyles';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface VisuallyHiddenProps {
  children: ReactNode;
  /** Boolean prop to visually hide children */
  isHidden: boolean;
}

function VisuallyHidden({ children, isHidden }: VisuallyHiddenProps): ReactElement {
  const { hidden } = useStyles();
  return isHidden ? <div className={hidden}>{children}</div> : <>{children}</>;
}

export default VisuallyHidden;
