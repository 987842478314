import React, { HtmlHTMLAttributes, ReactElement, useRef, PropsWithoutRef, KeyboardEvent } from 'react';

export const focusableSelectors = [
  'a[href]',
  'button',
  'textarea',
  'input:not([type="hidden"])',
  'select',
  '[tabindex]:not([tabindex="-1"])',
];

export type Props = PropsWithoutRef<HtmlHTMLAttributes<HTMLDivElement>>;

const FocusTrap = ({ children }: Props): ReactElement => {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key !== 'Tab') return;

    const focusableModalElements = modalRef.current?.querySelectorAll<HTMLFormElement>(focusableSelectors.join(', '));

    if (focusableModalElements) {
      const firstFocusableElement = focusableModalElements[0];
      const lastFocusableElement = focusableModalElements[focusableModalElements.length - 1];

      if (event.shiftKey && event.target === firstFocusableElement) {
        // handle focus back from first element
        event.preventDefault();
        lastFocusableElement?.focus();
      } else if (!event.shiftKey && event.target === lastFocusableElement) {
        // handle focus fwd from last element
        event.preventDefault();
        firstFocusableElement?.focus();
      }
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div ref={modalRef} onKeyDown={handleKeyDown}>
      {children}
    </div>
  );
};

export default FocusTrap;
