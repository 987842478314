/** Subdomains that are equivalent of the LangCode */
const subdomains = ['es', 'pt', 'nl', 'fr', 'fr', 'da', 'sv', 'ja', 'ko', 'zh', 'tr', 'no', 'fr'];

/** LangCodes with top level domains */
const tlds = [
  { code: 'en-GB', tld: 'co.uk' },
  { code: 'de', tld: 'de' },
] as const;

export default function getLinkBasedOnLangCode({
  languageCode,
  domain,
  path = '',
}: {
  languageCode: string;
  domain: string;
  path?: string;
}): string {
  if (subdomains.includes(languageCode)) {
    return `https://${languageCode}.${domain}.com${path}`;
  }
  const maybeTld = tlds.find(({ code }) => code === languageCode);
  if (maybeTld) {
    return `https://www.${domain}.${maybeTld.tld}${path}`;
  }
  return `https://www.${domain}.com${path}`;
}
