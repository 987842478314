import React from 'react';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import { ProgressBar } from '~app/components/core/ProgressBar';
import useStyles from './useStyles';

export type Props = {
  id?: string;
  value: number;
  total: number;
  isRTL: boolean;
  showPageCount: boolean;
  showPercentage: boolean;
};

const getCurrentValue = (value: number, total: number): number => {
  if (total <= 0) {
    return 0;
  }
  return Math.round((value / total) * 100);
};

const ClassicProgressBar = ({
  id = 'classic-progress',
  value,
  total,
  isRTL,
  showPageCount = true,
  showPercentage = true,
}: Props): React.ReactElement => {
  const theme = useSurveyTheme();
  const progressId = id;
  const progressLabelId = `${progressId}-label`;
  const progressBarId = `${progressId}-bar`;
  const currentValue = getCurrentValue(value, total);
  const accentColor = theme.secondaryAccentColor;

  const { progressBarContainer, progressBar, label } = useStyles({ isRTL: !!isRTL });

  return (
    <div id={progressId} className={progressBarContainer}>
      {showPageCount && (
        <span className={label}>
          {value}&nbsp;/&nbsp;{total}
        </span>
      )}
      <ProgressBar
        id={progressBarId}
        className={progressBar}
        aria-labelledby={progressLabelId}
        value={currentValue}
        borderColor={accentColor}
        progressColor={accentColor}
        radius={12}
        height={10}
      />
      {showPercentage && <span className={label}>{`${getCurrentValue(value, total)}%`}</span>}
    </div>
  );
};

export default ClassicProgressBar;
