import React, { FC, useContext, useMemo } from 'react';
import { defineMessages, T } from '@sm/intl';
import { StaticContext } from '@sm/webassets/StaticContext';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme';
import { SurveyLink } from '../SurveyLink';
import useStyles from './useStyles';
import getLinkBasedOnLangCode from '~app/helpers/languageLinks';

const DEFAULT_COPY = defineMessages({
  PRIVACY: {
    id: 'Survey.FooterPrivacyAndCookieNotice.Privacy',
    defaultMessage: 'Privacy',
    description: '[Type: Link][Vis: high] - Privacy anchor element',
  },
  COOKIE_NOTICE: {
    id: 'Survey.FooterPrivacyAndCookieNotice.CookieNotice',
    defaultMessage: 'Cookie Notice',
    description: '[Type: Link][Vis: high] - Cookie notice anchor element',
  },
  REPORT: {
    id: 'Survey.FooterPrivacyAndCookieNotice.Report',
    defaultMessage: 'Report a problem with this survey',
    description: '[Type: Link][Vis: high] - Report a problem anchor element',
  },
});

export type FooterPrivacyAndCookieNoticeProps = {
  /** Boolean to determine if we show the 'report a problem with this survey' anchor element */
  showReportProblem: boolean;
  /** URL for the report problem anchor element */
  reportProblemURL: string;
};

const FooterPrivacyAndCookieNotice: FC<FooterPrivacyAndCookieNoticeProps> = ({
  reportProblemURL,
  showReportProblem,
}) => {
  const {
    environment: { domain, languageCode },
  } = useContext(StaticContext);

  const COOKIE_NOTICE_URL = getLinkBasedOnLangCode({
    languageCode,
    domain,
    path: '/mp/legal/cookies/?ut_source=survey_pp',
  });
  const PRIVACY_BASICS_URL = getLinkBasedOnLangCode({
    languageCode,
    domain,
    path: '/mp/legal/privacy/?ut_source=survey_pp',
  });

  const surveyTheme = useSurveyTheme();
  const textColor = useMemo(() => {
    return surveyTheme.isDark ? '#FFFFFF' : 'rgb(51, 62, 72)';
  }, [surveyTheme.isDark]);

  const { text } = useStyles({ textColor });
  return (
    <p className={text}>
      <SurveyLink
        rel="noreferrer"
        target="_blank"
        href={PRIVACY_BASICS_URL}
        data-testid="FooterPrivacyAndCookieNotice__Privacy"
        textColor={textColor}
        aria-label="SurveyMonkey Privacy Notice"
      >
        <T desc={DEFAULT_COPY.PRIVACY} />
      </SurveyLink>{' '}
      &{' '}
      <SurveyLink
        rel="noreferrer"
        target="_blank"
        href={COOKIE_NOTICE_URL}
        data-testid="FooterPrivacyAndCookieNotice__Cookie"
        textColor={textColor}
        aria-label="SurveyMonkey Cookie Notice"
      >
        <T desc={DEFAULT_COPY.COOKIE_NOTICE} />
      </SurveyLink>
      {showReportProblem && (
        <>
          <span aria-hidden>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <SurveyLink href={reportProblemURL} textColor={textColor}>
            <T desc={DEFAULT_COPY.REPORT} />
          </SurveyLink>
        </>
      )}
    </p>
  );
};

export default FooterPrivacyAndCookieNotice;
