import React from 'react';

export type SurveyFormatViewProps = {
  name: string;
  children?:
    | React.ReactElement<{ active?: boolean; onSubmit(): void }>
    | ((isActive: boolean, setNextActive: () => void) => React.ReactElement);
  // TODO: move the following to an `options` prop
  noShield?: boolean;
  autoPadding?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export type SurveyFormatViewFactoryProps = SurveyFormatViewProps;

export type UseFormatViewReturn<T extends HTMLElement = HTMLElement> = {
  isActive: boolean;
};

export type SurveyFormatViewRenderProps = {
  isActive: boolean;
  setNextActive(): void;
};
