import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import useStyles from './useStyles';
import { Modal } from './Modal';

type Mode = 'TOOLTIP' | 'MODAL';

export type Props = {
  /** Element to which the tooltip is added */
  container: HTMLElement;
} & React.HTMLAttributes<HTMLSpanElement>;
/** Displays a tooltip on hover for large screens and a modal on click for small screens */
function Tooltip({ container, className, id = '' }: Props): React.ReactElement {
  /** container center position to display tooltip */
  const center = container.offsetWidth / 2;
  const text = container.getAttribute('data-tooltip') ?? '';
  const theme = useSurveyTheme();
  const { tooltip, tooltipBody } = useStyles({ center });
  const [showModal, setShowModal] = React.useState(false);
  const [mode, setMode] = React.useState<Mode>('TOOLTIP');

  const closeModal = (): void => {
    setShowModal(false);
    container.focus();
  };

  React.useEffect(() => {
    container.setAttribute('role', 'tooltip');
    // make tooltip anchor focusable
    if (!container.hasAttribute('tabIndex')) {
      container.setAttribute('tabIndex', '0');
    }

    // opens modal if matches small screen breakpoint
    const openModal = (): void => {
      const mql = window.matchMedia(`(max-width: ${theme?.breakpoints?.md.min ?? '769px'})`);
      if (mql.matches) {
        setMode('MODAL');
        setShowModal(true);
      } else {
        setMode('TOOLTIP');
      }
    };

    const handleClick = (): void => {
      openModal();
    };

    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.code === 'Enter' || e.code === 'Space') {
        e.preventDefault();
        e.stopPropagation();
        openModal();
      }
    };

    // listen for click, ENTER or SPACE to open modal
    container.addEventListener('click', handleClick);
    container.addEventListener('keydown', handleKeydown);
    return () => {
      container.removeEventListener('click', handleClick);
      container.removeEventListener('keydown', handleKeydown);
    };
  }, [container, mode, showModal, theme]);

  const modalEl = showModal ? <Modal tooltip={text} closeModal={closeModal} /> : null;
  const tooltipEl = (
    <div className={classNames(tooltip, className)}>
      <span id={id} className={tooltipBody} aria-hidden="true">
        {text}
      </span>
    </div>
  );
  const child = mode === 'MODAL' ? modalEl : tooltipEl;
  const containerEl = mode === 'MODAL' ? document.body : container;
  return ReactDOM.createPortal(child, containerEl);
}

export default Tooltip;
