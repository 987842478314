/**
 * @todo Add @sm/question-definitions as an explicit dependency; or, import
 * from explicit dependency source. i.e. @sm/question-widgets
 *
 * As a best-practice, dependencies of a dependency should not be imported.
 */
import { QuestionError } from '@sm/question-definitions';
import { ExternalValidator } from '@sm/question-widgets/respondent-survey';
import { ValidationMap, ValidationResult } from './types';

const validators: ValidationMap = {};

/**
 * Registers validator functions with a question
 * @param questionId A reference ID
 * @param validatorFns The validator functions
 */
export const registerValidator = (questionId: string, validatorFns: ExternalValidator[]): void => {
  validators[questionId] = validatorFns;
};

/**
 * Unregister a reference
 * @param questionId A reference ID
 */
export const unregisterValidator = (questionId: string): void => {
  if (validators[questionId]) {
    delete validators[questionId];
  }
};

/**
 * Run validators for a question
 * @param questionId The question to validate
 * @param force Force non-touched to validate
 * @returns {ValidationResult}
 */
export const validate = (questionId: string, force = false): ValidationResult => {
  const questionValidator = validators[questionId] || [];
  const errors = questionValidator.reduce<QuestionError[]>((prevErrors, validator) => {
    return [...prevErrors, ...(validator(force) ?? [])];
  }, []);
  return { isValid: !errors.length, errors };
};

/**
 * Runs all validators for all questions
 * @returns {ValidationResult}
 */
export const runValidators = (): ValidationResult => {
  const errors = Object.keys(validators).reduce<QuestionError[]>((prevErrors, questionId) => {
    const { errors: questionErrors } = validate(questionId, true);
    return [...prevErrors, ...questionErrors];
  }, []);
  return { isValid: !errors.length, errors };
};
