import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  SurveyVersionV2Query as SurveyVersionQuery,
  SurveyVersionV2QueryVariables as SurveyVersionQueryVariables,
} from '~lib/generatedGqlTypes';
import getSurveyVersionQuery from './SurveyVersionV2.graphql';
import { useAppDispatchV2 as useAppDispatch, useAppSelectorV2 as useAppSelector } from '~app/hooks';

import { ErrorType } from '../errors';
import { addError } from '../v2/slices/errorsSlice';
import { selectClientToken, selectSurvey, selectCollectorKey } from '../v2/slices/surveySlice';
import { CurrentSessionSurvey } from '../v2/types';

/**
 * Query the survey version and check if it has changed
 * @returns {boolean} true if the survey version has changed, false otherwise
 */
export const verifySurveyVersion = async (
  apolloClient: ApolloClient<object>,
  survey: CurrentSessionSurvey | undefined,
  clientToken: string | undefined,
  collectorKey: string | null
): Promise<boolean> => {
  try {
    const {
      data: { surveyVersion },
    } = await apolloClient.query<SurveyVersionQuery, SurveyVersionQueryVariables>({
      query: getSurveyVersionQuery,
      variables: {
        surveyId: survey?.id ?? '',
        collectionKey: collectorKey ?? '',
      },
      context: {
        ...(clientToken && {
          headers: {
            Authorization: `Bearer ${clientToken}`,
          },
        }),
      },

      // prevent cached survey version from being used
      fetchPolicy: 'network-only',
    });

    if (surveyVersion !== survey?.version) {
      return true;
    }
    return false;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return false;
};

/**
 * Hook that returns a function which will query the current survey version and apply
 * version changed errors if necessary
 */
export const useVerifySurveyVersion = (): (() => Promise<void>) => {
  const survey = useAppSelector(selectSurvey);
  const clientToken = useAppSelector(selectClientToken);
  const collectionKey = useAppSelector(selectCollectorKey);
  const dispatch = useAppDispatch();

  /** Reference to the main apolloClient */
  const apolloClient = useApolloClient();

  const verify = async (): Promise<void> => {
    const versionChanged = await verifySurveyVersion(apolloClient, survey, clientToken, collectionKey);
    if (versionChanged) {
      dispatch(addError({ code: ErrorType.INVALID_SURVEY_VERSION, detail: '', questionId: null, field: null }));
    }
  };

  return verify;
};

// init survey -> compare survey version with cookie survey version
export const useInitSurveyVerifyVersion = (surveyVersionChanged: boolean) => {
  const dispatch = useAppDispatch();

  return (): string | undefined => {
    if (surveyVersionChanged) {
      dispatch(addError({ code: ErrorType.INVALID_SURVEY_VERSION, detail: '', questionId: null, field: null }));
      return '-1';
    }
    return undefined;
  };
};
