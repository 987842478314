import React, { forwardRef } from 'react';
import classNames from 'classnames';
import FooterPoweredByLogoBlock, { FooterPoweredByLogoBlockProps } from '../FooterPoweredByLogoBlock';
import FooterPrivacyAndCookieNotice, { FooterPrivacyAndCookieNoticeProps } from '../FooterPrivacyAndCookieNotice';
import GooglePrivacyPolicy, { GooglePrivacyPolicyProps } from '../GooglePrivacyPolicy';
import useStyles from './useStyles';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface FooterContainerProps
  extends FooterPoweredByLogoBlockProps,
    FooterPrivacyAndCookieNoticeProps,
    GooglePrivacyPolicyProps,
    React.HTMLAttributes<HTMLDivElement> {
  /** A prop to render the google privacy policy component */
  isGoogleSubdomain: boolean;
  /** Boolean prop to show privacy and cookie notice anchor elements */
  showPrivacyCookiePolicy: boolean;
  /** (Optional) Change element-tag, default is <footer> */
  element?: 'footer' | 'div';
  /** Component does not accept children (jsx-conditional-tags workaround) */
  // children?: never;
  // survey id used for amplitude tracking
  surveyId: string;
  collectorType: string | undefined;
  collectorId: string | undefined;
}

/**
 * TODO: Move this component and it's dependant child-components under
 * SurveyTaking/components; rename to SurveyFooter
 */

function FooterContainer(
  {
    element: Element = 'footer',
    className,
    isGrayScale,
    showReportProblem,
    reportProblemURL,
    isGoogleSubdomain,
    googlePrivacyPolicyURL,
    showPrivacyCookiePolicy,
    surveyId,
    collectorId,
    collectorType,
    ...containerProps
  }: FooterContainerProps,
  ref?: React.Ref<HTMLDivElement>
): React.ReactElement {
  const { surveyFooter, privacyComponentContainer } = useStyles();

  return (
    <Element ref={ref} className={classNames(surveyFooter, className)} {...containerProps}>
      <FooterPoweredByLogoBlock
        surveyId={surveyId}
        collectorId={collectorId}
        collectorType={collectorType}
        {...{ isGrayScale }}
      />
      {isGoogleSubdomain && <GooglePrivacyPolicy {...{ googlePrivacyPolicyURL }} />}
      {showPrivacyCookiePolicy && (
        <div className={privacyComponentContainer}>
          <FooterPrivacyAndCookieNotice {...{ showReportProblem, reportProblemURL }} />
        </div>
      )}
    </Element>
  );
}
export default forwardRef<HTMLDivElement, FooterContainerProps>(FooterContainer);
