/**
 * @todo Add @sm/question-definitions as an explicit dependency; or, import
 * from explicit dependency source. i.e. @sm/question-widgets
 *
 * As a best-practice, dependencies of a dependency should not be imported.
 */
import { QuestionError } from '@sm/question-definitions';
import { SurveyError } from './types';
import { ErrorType } from './constants';

export const transformToSurveyErrors = <F extends QuestionError>(errors: F[]): SurveyError[] => {
  return errors.map(({ detail, questionId, type, fieldId }: F) => ({
    questionId,
    detail,
    code: type?.includes('REQUIRED') ? ErrorType.MISSING_REQUIRED_FIELD : ErrorType.FAILED_VALIDATION,
    field: fieldId ? [fieldId] : null,
  })) as SurveyError[];
};

export default transformToSurveyErrors;
