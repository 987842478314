import animate from './animate';

/**
 * How many animations are occurring (used in export to prevent OQAAT position
 * calculations from running unnecessarily and interfering with animation)
 * */
let animationCount = 0;

/**
 * Scroll an HTML element in to view
 * Opinionated - will scroll the element to the viewport (window) center unless `alignToTop` is set to `true`
 * @param {HTMLElement} element the HTML element to calculate position
 * @param {boolean} alignToTop If `true` scrolls to top of `element` (default `false`)
 * @param {number} customOffset offset to remove from calculated top-offset (default `0`)
 * @param {Function} onStart the callback when animation starts
 * @param {Function} onComplete the callback when animation completes
 */
// TODO: RAWR-1164 - replace this with `scroll-behaviour: smooth` once it has been fully adopted by safari
// (https://caniuse.com/css-scroll-behavior)
/* istanbul ignore next */
export const scrollIntoView = <T extends HTMLElement = HTMLElement>(
  element?: T | null,
  alignToTop = false,
  customOffset = 0,
  onStart: () => void = () => {},
  onComplete: () => void = () => {}
): void => {
  if (!element) return;
  const rect = element.getBoundingClientRect();
  /** total offset to the top of `element` */
  const topOffset = window.scrollY + rect.top - customOffset;
  const toValue = alignToTop
    ? topOffset
    : topOffset - window.innerHeight / 2 + Math.min(rect.height, window.innerHeight - 30) / 2;

  animate({
    fromValue: window.scrollY,
    toValue,
    onStart: () => {
      animationCount += 1;
      onStart();
    },
    onUpdate: (scrollTop: number, next: () => void) => {
      window.scrollTo({ left: 0, top: scrollTop });
      next();
    },
    onComplete: () => {
      animationCount -= 1;
      onComplete();
    },
  });
};

/** Whether a scroll animation is currently occurring */
export const isAnimating = (): boolean => animationCount > 0;
