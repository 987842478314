import { ExternalQuestion } from '@sm/question-widgets/respondent-survey';

/**
 * Group questions based each question's layout config
 * @param questions
 * @description
 * - if a question has layout config as new row, it will start a new group
 * - if a question has layout config as right aligned, it will be grouped with the previous group together
 * @example
 * input = [
 *   { layout: { placement: 'NEW_ROW' }},
 *   { layout: { placement: 'RIGHT_ALIGNED' }},
 *   { layout: { placement: 'NEW_ROW' }},
 *   { layout: { placement: 'RIGHT_ALIGNED' }},
 * ]
 *
 * output = [
 *   [ { layout: { placement: 'NEW_ROW' }}, { layout: { placement: 'RIGHT_ALIGNED' }} ],
 *   [ { layout: { placement: 'NEW_ROW' }}, { layout: { placement: 'RIGHT_ALIGNED' }} ]
 * ]
 */
const groupQuestionsWithLayoutConfig = (questions: ExternalQuestion[]): ExternalQuestion[][] => {
  return questions.reduce<ExternalQuestion[][]>((groups, question) => {
    if ('layout' in question && 'placement' in question.layout && question.layout.placement === 'RIGHT_ALIGNED') {
      const preGroup = groups.pop();
      const newGroup = preGroup ? [...preGroup, question] : [question];
      groups.push(newGroup);
    } else {
      groups.push([question]);
    }
    return groups;
  }, []);
};

export default groupQuestionsWithLayoutConfig;
