const setSurveyTakingDocumentTitle = (isEnabled: boolean, isWhiteLabel: boolean, title = ''): string => {
  const defaultTitle = `${isWhiteLabel ? 'Research.net' : 'SurveyMonkey'} Powered Online Survey`;

  if (isEnabled) {
    const textTitle = title?.replace(/<[^>]*>/g, '');
    return textTitle.toLowerCase().includes('survey') ? textTitle : `${textTitle} Survey`;
  }
  return defaultTitle;
};

export default setSurveyTakingDocumentTitle;
