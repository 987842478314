import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';

import { QuestionAnswers, PageQuestions } from '../v2/types';

/**
 * Decrypts answers from localstorage item answers_<collectorKey> HTML entities in a string.
 * @param collectorKey
 * @returns decrypted answers
 */

export const decryptAnswers = (collectorKey = 'collectKey'): QuestionAnswers => {
  if (typeof window === 'undefined') {
    return {}; // SSR
  }
  const encryptedAnswers = window.localStorage.getItem(`answers_${collectorKey}`);
  let decryptedAnswers = {};
  if (encryptedAnswers) {
    try {
      const bytes = AES.decrypt(encryptedAnswers, collectorKey);
      decryptedAnswers = JSON.parse(bytes.toString(enc));
    } catch (error: unknown) {
      // return empty object below
    }
  }
  return decryptedAnswers;
};

/*
 * helper without decryption
 */
export const overrideAnswersFromLocalStorage = (
  questions: PageQuestions | undefined | null,
  localStorageAnswers: QuestionAnswers
): QuestionAnswers | {} | undefined => {
  const overriddenResponses = questions?.items?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: {
        questionId: curr.id,
        values: localStorageAnswers[curr.id]?.values ?? [],
        touched: false,
        isDirty: false,
      },
    };
  }, {});

  return overriddenResponses;
};
