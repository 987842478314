import React from 'react';
import { RichText } from '../../core/RichText';
import useSurveyStyles from './useStyles';

export type Props = {
  text: string;
  element?: 'div' | 'span' | 'p';
};

const PageDescription: React.FC<Props> = ({ element = 'div', text }) => {
  const { pageDescription } = useSurveyStyles();
  return <RichText element={element} text={text} className={pageDescription} data-testid="PageDescription__Text" />;
};

export default PageDescription;
