import React, { ReactElement } from 'react';

import { Box } from '@wds/box';
import { Button } from '@wds/button';

export type CallToActionType = {
  label: string;
  uri: string;
};

const CallToActionButtons = ({
  ctaButton0,
  ctaButton1,
}: {
  ctaButton0?: CallToActionType | null;
  ctaButton1?: CallToActionType | null;
}): ReactElement => (
  <Box display="flex" flexDirection="row" flexAlign="center" flexJustify="center">
    {ctaButton0 && (
      <Box mr={1}>
        <Button color="upgrade" size="sm" href={ctaButton0.uri}>
          {ctaButton0.label}
        </Button>
      </Box>
    )}
    {ctaButton1 && (
      <Box ml={1}>
        <Button color="primary" size="sm" href={ctaButton1.uri}>
          {ctaButton1.label}
        </Button>
      </Box>
    )}
  </Box>
);

CallToActionButtons.defaultProps = {
  ctaButton0: null,
  ctaButton1: null,
};

export default CallToActionButtons;
