import { SurveyError } from '..';

/** Helper to sort by `questionOrder` (if it exists and both questionIds exist in it) */
export const sortByQuestionOrder =
  (order: string[]) =>
  ({ questionId: a }: SurveyError, { questionId: b }: SurveyError): number => {
    if (!order || !a || !b || !order.includes(a) || !order.includes(b)) {
      return 0;
    }
    return order.indexOf(a) - order.indexOf(b);
  };

export default sortByQuestionOrder;
