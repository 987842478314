export default (
  forceHide: boolean,
  isRequestFromSearchEngine: boolean,
  collectorType: string,
  collectionMethod: string | null
): boolean => {
  if (forceHide) {
    return false;
  }

  const isWeblink = collectorType === 'WEBLINK';
  const isNormalCollection = collectionMethod === 'NORMAL';

  return isRequestFromSearchEngine && isWeblink && isNormalCollection;
};
