import React, { useState } from 'react';
import { sanitizeString } from '@sm/utils';
import { uniqueId } from '@wds/utils';
import classNames from 'classnames';
import useStyles from './useStyles';
import { Tooltip } from '../Tooltip';

type ElementProps = React.HTMLAttributes<HTMLHeadingElement | HTMLDivElement | HTMLSpanElement | HTMLParagraphElement>;
export type Props = {
  /* Node that will have content dangerously set as its inner HTML */
  element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span' | 'p';
  /* Formatted string of HTML */
  text: string;
} & ElementProps;

// Support iframe video embeds and their matching attributes
const sanitizeOptions = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'sandbox', 'scrolling'],
};

function RichText({
  id: elementId,
  element: Element = 'div',
  text,
  className,
  ...containerProps
}: Props): React.ReactElement {
  const [tooltipNodes, setTooltipNodes] = useState<HTMLElement[]>([]);
  const { richTextContent } = useStyles();
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (tooltipRef?.current) {
      const el = tooltipRef?.current;
      setTooltipNodes(Array.from(el.querySelectorAll('[data-tooltip]')));
    }
  }, []);

  return (
    <>
      <Element
        id={elementId}
        className={classNames(richTextContent, className)}
        dangerouslySetInnerHTML={{
          __html: sanitizeString(text, sanitizeOptions),
        }}
        ref={tooltipRef}
        translate="no"
        {...containerProps}
      />
      {tooltipNodes.map(container => {
        const id = uniqueId('rich-text-tooltip');
        container.setAttribute('aria-describedby', id);
        return <Tooltip key={id} container={container} id={id} />;
      })}
    </>
  );
}

export default RichText;
