import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { SurveyTitleHorizontalAlignment } from '~lib/generatedGqlTypes';

const useSurveyStyles = createSurveyStyles<'surveyTitle', { align: SurveyTitleHorizontalAlignment }>(theme => ({
  surveyTitle: {
    color: theme?.titleColor ?? '#00bf6f',
    lineHeight: 1.25,
    paddingBottom: 24,
    margin: 0,
    // use `start` to support RTL (as 'LEFT' means `right` in RTL languages)
    textAlign: ({ align }) => (align === 'CENTER' ? 'center' : 'start'),
    ...theme?.surveyTitle,
    // reset font weight changed by the theme above
    '& strong': {
      fontWeight: 'bold !important',
    },
  },
}));

export default useSurveyStyles;
