import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonStyleProps = { size?: ButtonSize };
type ButtonStyleClasses = 'surveyButton';

type SizeOption = {
  padding: (string | number)[] | string | number;
  height: number;
  fontSize: number;
};
type SizeMap = Record<ButtonSize, SizeOption>;
const defaultSize = 'md';
const sizeMap: SizeMap = {
  sm: { padding: `0 12px`, height: 30, fontSize: 13 },
  md: { padding: `0 24px`, height: 40, fontSize: 15 },
  lg: { padding: `0 36px`, height: 50, fontSize: 15 },
};
function getSizeOption(size: ButtonSize = defaultSize): SizeOption {
  return sizeMap[size];
}

const useStyles = createSurveyStyles<ButtonStyleClasses, ButtonStyleProps>(theme => ({
  surveyButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',

    color: theme.secondaryAccentColor ?? {},
    backgroundColor: theme.primaryAccentColor ?? {},

    fontFamily: theme.questionBody?.fontFamily ?? {},
    fontSize: ({ size }) => getSizeOption(size).fontSize,

    border: [1, 'solid', 'transparent'],
    borderRadius: 2,

    padding: ({ size }) => getSizeOption(size).padding,

    maxWidth: '100%',
    minWidth: 60,
    minHeight: ({ size }) => getSizeOption(size).height,
    height: 'auto',
    cursor: 'pointer',
    overflowWrap: 'break-word',
    transition: 'outline-offset 0.2s linear',
    textDecoration: 'none',
    '&:focus, &:hover': {
      outlineOffset: 5,
      outline: `1px solid ${theme.answerColor}`,
      // Safari seems to have an outline bug, may not render without boxshadow
      boxShadow: '0 0 4px transparent',
    },
    '&[aria-disabled]': {
      opacity: 0.6,
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
