import SurveyFormat from './surveyFormatEnum';
import { CurrentSessionSurvey } from '../v2/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const extrapolateSurveyData = (survey: CurrentSessionSurvey) => {
  return {
    isOQAAT: survey.format === SurveyFormat.OQAAT,
    isClassicMode: survey.format === SurveyFormat.CLASSIC,
    isFooterEnabled: survey.design?.footer?.enabled,
    isProgressBarEnabled: !!survey.design?.progressBar?.enabled,
    isQuestionNumberingEnabled: survey.design?.questionNumbering?.enabled,
    isSurveyTitleEnabled: survey.design?.surveyTitle.enabled,
    logo: {
      enabled: survey.design?.logo?.enabled,
      size: survey.design?.logo?.size,
      height: survey.design?.logo?.height,
      width: survey.design?.logo?.width,
      verticalAlignment: survey.design?.logo?.verticalAlignment,
      imageUrl: survey.design?.logo?.image?.url,
      description: survey.design?.logo?.image?.description,
    },
    totalPages: survey?.pages?.totalCount,
    showPageTitles: survey.design?.showPageTitles,
    showPageNumbers: !!survey.design?.showPageNumbers,
    showRequiredQuestionAsterisks: survey.design?.showRequiredQuestionAsterisks,
    surveyTitle: survey.title,
    surveyTitleAlignment: survey.design?.surveyTitle.horizontalAlignment,
    surveyTitleHTML: survey.titleHTML,
    themeVersion: survey.design?.theme?.version,
    totalQuestionsCount: survey.questionCount,
    answerableQuestionCount: survey?.answerableQuestionCount,
    labels: {
      ok: survey.navigation?.ok.label,
      previous: survey.navigation?.previous.label,
      next: survey.navigation?.next.label,
      done: survey.navigation?.done.label,
    },
    progressBar: {
      position: survey.design?.progressBar?.position,
      showPercent: survey.design?.progressBar?.showPercent,
      showPageCount: survey.design?.progressBar?.showPageNumber,
    },
    languages: [survey.defaultSurveyLanguage, ...(survey.availableSurveyLanguages ?? [])].sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    ),
  };
};

export default extrapolateSurveyData;
