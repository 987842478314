import React from 'react';
import { OQAATView, ClassicView } from './views';
import { SurveyFormat } from './constants';
import { SurveyFormatViewFactoryProps } from './types';
import { useAppSelectorV2 } from '~app/hooks';
import { selectSurveyFormat } from '~app/pages/SurveyTaking/v2/slices/surveySlice';

export function SurveyFormatViewFactory({ name, ...props }: SurveyFormatViewFactoryProps): React.ReactElement {
  const format = useAppSelectorV2(selectSurveyFormat);

  const View = React.useMemo(() => {
    switch (format) {
      case SurveyFormat.ONE_QUESTION_AT_A_TIME: {
        return OQAATView;
      }
      case SurveyFormat.CLASSIC:
      default: {
        return ClassicView;
      }
    }
  }, [format]);

  return <View name={name} {...props} />;
}

export default SurveyFormatViewFactory;
