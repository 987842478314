/*eslint spaced-comment: ["error", "never", { "exceptions": ["NOSONAR"] }] */
import { useEffect, useLayoutEffect } from 'react';

/**
 * SSR Safe version of useLayoutEffect
 * @see https://github.com/mntv-webplatform/smweb/blob/master/apps/contentweb/src/app/pages/MRX/helpers/useIsomorphicLayoutEffect.js
 *
 * Note: `NOSONAR` is used to bypass a `minor:code-smell` error that states `createElement` is deprecated.
 * The eslint rule above disables the spaced comments to use NOSONAR as stated in the docs.
 */
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' && typeof window?.document?.createElement !== 'undefined' //NOSONAR
    ? useLayoutEffect
    : useEffect;

export default useIsomorphicLayoutEffect;
