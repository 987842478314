/**
 * @todo Add @sm/question-definitions as an explicit dependency; or, import
 * from explicit dependency source. i.e. @sm/question-widgets
 *
 * As a best-practice, dependencies of a dependency should not be imported.
 */
import { QuestionError } from '@sm/question-definitions';
import { ErrorType } from './constants';
import { SurveyError } from './types';
import { decodeHtmlEntities } from '../helper/decodeHtmlEntities';

function transformFromSurveyErrors<T extends QuestionError>(errors: SurveyError[]): T[] {
  return errors.map(
    ({ questionId, field, detail, code }: SurveyError) =>
      ({
        questionId,
        detail: decodeHtmlEntities(detail),
        // defaults to "TEXT_LENGTH", presently no way of knowing (consider adding UNKNOWN to SMQ)
        type: code === ErrorType.MISSING_REQUIRED_FIELD ? 'REQUIRED' : 'TEXT_LENGTH',
        fieldId: field?.[0],
      } as T)
  );
}

export default transformFromSurveyErrors;
