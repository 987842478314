import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

type FooterClassNames = 'surveyFooter' | 'privacyComponentContainer';
const useStyles = createSurveyStyles<FooterClassNames>({
  surveyFooter: {},
  privacyComponentContainer: {
    paddingTop: 80,
    margin: '0 auto',
    textAlign: 'center',
  },
});

export default useStyles;
