import { createUseStyles } from 'react-jss';

const listSpacing = {
  margin: [16, 0],
  padding: [0, 0, 0, 40],
};
const mediaReset = {
  border: 0,
  maxWidth: '100%',
};

const useStyles = createUseStyles({
  richTextContent: {
    // Typography
    '& a': {
      // Links are not themed by default. Users must select a link color in the editor
      color: 'blue',
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'currentColor auto 1px',
      },
    },
    '& ol': {
      ...listSpacing,
      listStyleType: 'decimal',
    },
    '& ul': {
      ...listSpacing,
      listStyle: 'disc outside none',
      '& ul li': {
        listStyle: 'circle',
      },
    },
    '& p': {
      margin: [13, 0],
    },
    '& b': {
      fontWeight: 'bold',
    },
    '& strong': {
      fontWeight: 'bold',
    },

    '& em': {
      fontStyle: 'italic',
    },

    '& i': {
      fontStyle: 'italic',
    },

    '& big': {
      fontSize: 'larger',
    },

    '& small': {
      fontSize: 'smaller',
    },
    '& sup': {
      fontSize: '75%',
      lineHeight: 0,
      position: 'relative',
      top: '-0.5em',
      verticalAlign: 'baseline',
    },
    // Media embeds
    '& iframe': mediaReset,
    '& img': {
      ...mediaReset,
      height: 'auto',
      '-ms-interpolation-mode': 'bicubic',
    },
    '& span[data-tooltip]': {
      position: 'relative',
      display: 'inline',
      borderBottom: [[1, 'dashed']],
      textDecoration: 'inherit',
      cursor: 'pointer',
    },
  },
});

export default useStyles;
