import React, { ReactElement, ReactNode } from 'react';
import { Box } from '@wds/box';

import useStyle from './useStyles';

const MessageBody = ({ children }: { children: ReactNode }): ReactElement => {
  const { seoBannerPlug } = useStyle();

  return (
    <Box display="flex" flexDirection="column" flexAlign="center" flexJustify="center" ml={1} mr={1}>
      <div className={seoBannerPlug}>{children}</div>
    </Box>
  );
};

export default MessageBody;
