import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { SurveyTheme as SurveyThemeType } from '@sm/webassets/SurveyTheme/types';

import { isPartialLeft, isPartialHalf, isPartialThird, isBanner, isFull } from '~app/helpers/themeLayout';

type LayoutOptionType = Required<SurveyThemeType['layout']>;

type StyleNames = 'progressBarContainer' | 'text' | 'progressBar';
type StyleProps = {
  isRTL: boolean;
};

// 😖
type LeftRightVals = {
  left: string;
  right: string;
};

function getWidthFromLayout(layout: LayoutOptionType): string {
  let width = '';

  if (isFull(layout) || isBanner(layout)) {
    width = '100%';
  } else if (isPartialHalf(layout)) {
    width = '50%';
  } else if (isPartialThird(layout)) {
    width = '66%';
  }
  return width;
}

function getLeftRightFromLayout(layout: LayoutOptionType): LeftRightVals {
  // Default to browser behavior
  let left = 'auto';
  let right = 'auto';

  /**
   *  PartialLeft => Partial (half or third) space is left empty on the LEFT side while survey content is on the right.
   *    * right = 0 ensures the bar is at the edge on right side.
   *    * left is set to 'auto'
   *
   *  PartialRight:
   *    * ProgressBar should be on the edge of left side => left = 0
   *    * right = 'auto'
   *
   *  isFull and isBanner:
   *    * In both these cases, progress bar already has 100% width, left = 0 ensures it sticks to left edge
   *    * right = 'auto
   */

  if (isPartialLeft(layout)) {
    right = '0';
  } else {
    left = '0';
  }

  return {
    left,
    right,
  };
}

const useStyles = createSurveyStyles<StyleNames, StyleProps>(theme => {
  const { left, right } = getLeftRightFromLayout(theme.layout);

  return {
    progressBarContainer: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      direction: 'ltr',
      background: theme.primaryAccentColor,
      position: 'fixed',
      bottom: 0,
      height: 48,
      left,
      right,
      width: getWidthFromLayout(theme.layout),

      [`@media (max-width: ${theme.breakpoints?.sm.max})`]: {
        // for partial layouts (except banner), container should be 2/3 of the screen width between 640px and 768px
        width: !isFull(theme.layout) && !isBanner(theme.layout) ? '66%' : '100%',
      },
      [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
        width: '100%',
      },
    },
    text: {
      fontFamily: theme?.questionBody?.fontFamily ?? null,
      color: theme.secondaryAccentColor ?? {},
      direction: ({ isRTL }: StyleProps) => (isRTL ? 'rtl' : null),
      fontSize: 15,
    },
    progressBar: {
      width: 200,
      marginLeft: 20,
      // hide the progress bar at appropriate breakpoints depending on layout
      [`@media (max-width: ${theme.breakpoints?.md.max})`]: {
        display: isPartialHalf(theme.layout) ? 'none' : 'block',
      },
      [`@media (max-width: ${theme.breakpoints?.sm.max})`]: {
        display: isPartialThird(theme.layout) || isPartialHalf(theme.layout) ? 'none' : 'block',
      },
      [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
        display: 'block',
      },
      [`@media (max-width: ${theme.breakpoints?.xxs.max})`]: {
        display: 'none',
      },
    },
  };
});

export default useStyles;
