import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

export type SurveyLinkClassName = 'surveyAnchor';

export type SurveyLinkStyleProps = {
  underline: string;
  textColor?: string;
};

const decorationMap = new Map([
  ['auto', { default: 'none', focus: 'underline' }],
  ['always', { default: 'underline', focus: 'underline' }],
  ['never', { default: 'none', focus: 'none' }],
  ['inverse', { default: 'underline', focus: 'none' }],
]);

const useStyles = createSurveyStyles<SurveyLinkClassName, SurveyLinkStyleProps>(theme => ({
  surveyAnchor: {
    '-webkit-font-smoothing': 'antialiased',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 400,
    color: ({ textColor }) => textColor ?? '',
    textDecoration: ({ underline }) => decorationMap.get(underline)?.default ?? 'none',
    '&:hover, &:focus': {
      textDecoration: ({ underline }) => decorationMap.get(underline)?.focus ?? 'underline',
      outlineOffset: 1,
      outline: `1px solid ${theme.answerColor}`,
      // Safari seems to have an outline bug, may not render without boxshadow
      boxShadow: '0 0 1px transparent',
    },
  },
}));

export default useStyles;
