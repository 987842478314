import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  hidden: {
    clip: ' rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    width: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
});

export default useStyles;
