import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';

const useStyle = createUseStyles((theme: Theme) => {
  const mediaQueryMinScreenXL = `@media (min-width: ${theme.breakpoints.xl}px)`;

  return {
    seoBanner: {
      position: 'relative',
      backgroundColor: theme?.palette.background.light,
      color: theme.palette.background.light,
      fontFamily: theme.type.fontFamily.base,
      fontWeight: 'normal',
      fontSize: theme.type.fontSize?.body,
      lineHeight: 1.26,
      padding: `8px 2.2rem ${8 * 0.75}px`,
      borderBottom: theme.border?.muted,
    },
    embeddedContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexAlign: 'center',
      flexJustify: 'center',
      [mediaQueryMinScreenXL]: {
        flexDirection: 'row',
      },
    },
    seoBannerContents: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      paddingBottom: '0.5rem',

      [mediaQueryMinScreenXL]: {
        paddingTop: '1rem',
      },
    },
    closeXButton: {
      position: 'absolute',
      right: '0.625rem',
      top: '0.625rem',
      '& button': {
        border: 'none',
        backgroundColor: 'transparent',
        height: 'auto',
        padding: '0',
      },
      '& svg': {
        width: 18,
        height: 18,
      },
    },
  };
});

export default useStyle;
