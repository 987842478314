import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  selectContainerStyles: {
    maxWidth: '100%',
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    transition: 'all 0.1s linear',

    borderRadius: `4px`,
    color: '#000',
  },
  selectStyles: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    border: `1px solid transparent`,
    '&:focus-within, &:hover': {
      border: `1px solid black`,
    },
    lineHeight: 'normal',
    outline: 'none',

    fontWeight: 'inherit',
    fontFamily: 'inherit',
    fontSize: 'inherit',

    backgroundColor: '#fff',
    color: 'inherit',

    maxWidth: '100%',
    padding: '6px 1.75em 6px 6px',

    verticalAlign: 'bottom',
    textSizeAdjust: 'auto',

    // Prevent de fault chevron from showing
    mozAppearance: 'none',
    webkitAppearance: 'none',
    appearance: 'none',

    transition: 'border 0.1s linear',
  },

  selectIconStyles: {
    position: 'absolute',
    width: '1em',
    height: '1em',
    display: 'block',
    margin: '0 .5em',
    right: 0,
    zIndex: 2,
    pointerEvents: 'none',
  },
  disabledStyles: {
    opacity: 0.4,
  },
});

export default useStyles;
