import { createUseStyles } from 'react-jss';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ProgressBarStyleProps {
  /** The current value in percentage */
  value: number;
  /** the color of the progress bar border */
  borderColor?: string;
  /** progress foreground colour (a.k.a fill) */
  progressColor?: string;
  /** inner progress background colour */
  emptyColor?: string;
  /**  border-radius in px */
  radius?: number;
  /** height in px */
  height?: number;
}

const useStyles = createUseStyles({
  container: {
    border: ({ borderColor }: ProgressBarStyleProps) => `1px solid ${borderColor}`,
    borderRadius: ({ radius }: ProgressBarStyleProps) => radius,
    height: ({ height }: ProgressBarStyleProps) => height,
    backgroundColor: ({ emptyColor }: ProgressBarStyleProps) => emptyColor,
  },
  bar: {
    width: ({ value }: ProgressBarStyleProps) => `${value}%`,
    height: '100%',
    transition: 'width 200ms',
    backgroundColor: ({ progressColor }: ProgressBarStyleProps) => progressColor,
    borderRadius: 12,
  },
});

export default useStyles;
