/* eslint-disable no-param-reassign */

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type CreateHeading = (offset: number, reset?: boolean) => HeadingType;
/**
 * Cascade Headings
 * Always start with an H1 heading level element.
 * If SurveyTitle is not enabled, PageTitle will become H1
 * If both Titles are not enabled, the effect cascades down.
 *
 * @see https://gist.github.com/t-schemmer/f7591f8eff678c3af274c90f0cf9f86a
 */
export const createHeadingLevel = (initial = 0): CreateHeading => {
  let headingLevel = initial;
  return (offset = 1, reset = false) => {
    if ((headingLevel === 0 && offset <= 0) || offset > 1) {
      offset = 1;
    }
    if (reset) {
      headingLevel = 1;
    }
    headingLevel = Math.max(headingLevel === 0 ? 1 : 2, Math.min(headingLevel + offset, 6));
    return `h${headingLevel}` as HeadingType;
  };
};

export default createHeadingLevel;
