// from api/graphapi/src/schema/responsesRefactored, graphapi will be cleaned up after retiring respweb

const surveyTakingSubdomains = [
  'www',
  'zh',
  'da',
  'nl',
  'fi',
  'fr',
  'de',
  'it',
  'jp',
  'ko',
  'no',
  'pt',
  'ru',
  'es',
  'sv',
  'tr',
  // add regional subdomains
  'ca',
  'eu',
  'uk',
];

// country TLD, that have .com domains, eg. surveymonkey.com.mx
const validSubdomains = [
  'surveymonkey',
  'localmonkey',
  'www',
  'monkeytest1',
  'monkeytest2',
  'monkeytest3',
  'localresearch',
];
const defaultSubdomain = ['www'];

const subdomainsInSurveyTakingSubdomains = (subdomains: string[]): boolean =>
  // force to add type annotation by TS type check
  subdomains.reduce((found: boolean, segment) => found && surveyTakingSubdomains.includes(segment), true);

const isValidSubdomain = (requestSubdomains?: string[], collectorSubDomain?: string | null): boolean => {
  const newRequestSubdomains =
    !requestSubdomains || requestSubdomains.length === 0 ? defaultSubdomain : requestSubdomains;
  const newCollectorSubdomain = `${collectorSubDomain ?? ''}`.toLowerCase();

  const collectorSubdomainSegments = newCollectorSubdomain.split('.');

  const newRequestSubdomainsAsString = newRequestSubdomains.map(segment => `${segment ?? ''}`.toLowerCase()).join('.');

  // matching collector subdomain
  const matchCollectorSubdomain = newRequestSubdomainsAsString === newCollectorSubdomain;
  // check if collector sub domain is in the allowed list
  const isCollectorSubdomainInAllowedList =
    matchCollectorSubdomain || subdomainsInSurveyTakingSubdomains(collectorSubdomainSegments);

  if (!matchCollectorSubdomain && isCollectorSubdomainInAllowedList) {
    const isRegionalSubdomain = newRequestSubdomains.reduce(
      (_, domainPart) => validSubdomains.includes(domainPart),
      false
    );
    const subdomains = isRegionalSubdomain ? defaultSubdomain : newRequestSubdomains;

    return subdomainsInSurveyTakingSubdomains(subdomains);
  }

  return matchCollectorSubdomain;
};

export default isValidSubdomain;
