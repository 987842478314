import React, { ReactElement, ReactNode } from 'react';

import CallToActionButtons, { CallToActionType } from '../CallToActionButtons';
import Logo from '../Logo';
import MessageBody from '../MessageBody';

export default ({
  children,
  callToActionButtons,
}: {
  children: ReactNode;
  callToActionButtons: CallToActionType[];
}): ReactElement => {
  const [ctaButton0, ctaButton1] = callToActionButtons;

  return (
    <>
      <Logo />

      <MessageBody>{children}</MessageBody>

      <CallToActionButtons ctaButton0={ctaButton0} ctaButton1={ctaButton1} />
    </>
  );
};
