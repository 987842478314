import React from 'react';
import { SurveyTitleHorizontalAlignment } from '~lib/generatedGqlTypes';
import { RichText } from '../../core/RichText';
import useSurveyStyles from './useStyles';

export type Props = {
  id?: Element['id'];
  align?: SurveyTitleHorizontalAlignment;
  text: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & React.HTMLAttributes<HTMLHeadElement>;

const SurveyTitleV2: React.FC<Props> = ({ id, element = 'h1', text, align = 'LEFT', ...otherProps }) => {
  const { surveyTitle } = useSurveyStyles({ align });
  return (
    <RichText
      id={id}
      element={element}
      text={text}
      className={surveyTitle}
      data-testid="SurveyTitleV2__Title"
      {...otherProps}
    />
  );
};

export default SurveyTitleV2;
