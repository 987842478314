import { useEffect } from 'react';

/** Globally shared reference to track the next element that'll receive focus */
let nextFocusElRef: HTMLElement | null = null;

/** Globally shared reference for a post-focus callback */
let blurPostFocusCallbacks: (() => void)[] = [];
let blurPostFocusCallback: (() => void) | null = null;

type FocusableElementWithDisabled = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement;
const isFocusableElementWithDisabled = (el: HTMLElement): el is FocusableElementWithDisabled =>
  ['INPUT', 'TEXTAREA', 'SELECT', 'BUTTON'].includes(el.nodeName);

type FocusableElementWithHref = HTMLAnchorElement | HTMLAreaElement;
const isFocusableElementWithHref = (el: HTMLElement): el is FocusableElementWithHref =>
  ['A', 'AREA'].includes(el.nodeName);

type FocusableElWithControls = HTMLAudioElement | HTMLVideoElement;
const isFocusableElWithControls = (el: HTMLElement): el is FocusableElWithControls =>
  ['AUDIO', 'VIDEO'].includes(el.nodeName);

/**
 * Helper to check if `el` can be focused
 * Ref: https://allyjs.io/data-tables/focusable.html
 */
const isFocusable = (el: HTMLElement): boolean => {
  if (el.getAttribute('tabindex')) {
    return true;
  }
  if (el.nodeName === 'LABEL' || el.nodeName === 'LEGEND') {
    // special case - Label redirects to focus element
    // Legend TBD - appears to do the same
    return true;
  }

  if (isFocusableElementWithDisabled(el)) {
    return !el.disabled;
  }
  if (isFocusableElementWithHref(el)) {
    return !!el.href;
  }
  if (isFocusableElWithControls(el)) {
    return el.controls;
  }
  if (el.nodeName === 'DETAILS') {
    return true;
  }
  return false;
};

/** @ignore - do not use - exported for test only */
export const _isFocusable = isFocusable;

/** Sets reference for next focus target - before it receives focus */
const setNextFocusElement = (e: FocusEvent): void => {
  if (e.target && isFocusable(e.target as HTMLElement)) {
    nextFocusElRef = e.target as HTMLElement;
  } else {
    nextFocusElRef = null;
  }
};

/**
 * Decided if `callback` should be called immediately after the
 * next element received focus
 */
export const deferToAfterFocusChanged = (callback: () => void): void => {
  if (!nextFocusElRef) {
    // call imediately if there is no next focus target
    callback();
    return;
  }
  // register callback
  blurPostFocusCallbacks = [...blurPostFocusCallbacks, callback];
  blurPostFocusCallback = () => {
    blurPostFocusCallbacks.forEach(fn => fn());
    // reset variables
    blurPostFocusCallbacks = [];
    blurPostFocusCallback = null;
  };
};

/**
 * Wrapper to allow a registered function to be called after the next element
 * has received focus, to circumvent layout shifts before the click is registerd -
 * which could cause the element's `onClick` to never fire if the layout-shift
 * moves it from under the cursor.
 */
export const runAfterFocusChangedFn = (): void => {
  if (blurPostFocusCallback) {
    blurPostFocusCallback();
  }
  nextFocusElRef = null;
};

/**
 * Cleanup in case `runAfterFocusChangedFn` is not handled by the target
 * element's `onClick` handler
 */
const cleanupCall = (e: FocusEvent | MouseEvent): void => {
  const nodeName = (e.target as HTMLLabelElement | null)?.nodeName;
  // don't run for `<label/>` - wait for associated element to receive focus
  if ((nextFocusElRef || blurPostFocusCallback) && nodeName !== 'LABEL') {
    runAfterFocusChangedFn();
  }
};

/**
 * Helper to register the `deferToAfterFocusChanged` global
 * auxillary event-handlers, that register next targets and
 * calls `runAfterFocusChangedFn` in case it is not handled
 * manually by the target element's `onClick` handler
 */
export const useGlobalAfterFocusChangedHandler = (): void => {
  useEffect(() => {
    // register the next focus target - called before blur
    window.addEventListener('mousedown', setNextFocusElement);
    // listen to `click` to bubble up for cleanup
    window.addEventListener('click', cleanupCall);

    return () => {
      window.removeEventListener('mousedown', setNextFocusElement);
      window.removeEventListener('click', cleanupCall);
    };
  }, []);
};
