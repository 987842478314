import React, { FC } from 'react';
import { defineMessages, T } from '@sm/intl';
import { SurveyLink } from '../SurveyLink';
import useStyles from './useStyles';

const DEFAULT_COPY = defineMessages({
  PRIVACY: {
    id: 'Survey.GooglePrivacyPolicy.Privacy',
    defaultMessage: 'Google Privacy Policy',
    description: '[Type: Link][Vis: med] - Google Privacy Policy anchor element',
  },
});

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface GooglePrivacyPolicyProps {
  /** URL for the google privacy policy anchor element */
  googlePrivacyPolicyURL: string;
}
const GooglePrivacyPolicy: FC<GooglePrivacyPolicyProps> = ({ googlePrivacyPolicyURL }) => {
  const { anchorContainer } = useStyles();
  return (
    <p className={anchorContainer}>
      <SurveyLink href={googlePrivacyPolicyURL} underline="always">
        <T desc={DEFAULT_COPY.PRIVACY} />
      </SurveyLink>
    </p>
  );
};

export default GooglePrivacyPolicy;
