import React from 'react';
import classnames from 'classnames';

import { CaretDownOutlineIcon } from '@sm/question-ui/respondent-survey';

import useStyles from './useStyles';

export type NativeSelectProps = {
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  icon?: React.ReactElement;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

function NativeSelect({
  required,
  children,
  disabled,
  className,
  icon = <CaretDownOutlineIcon />,
  ...props
}: NativeSelectProps): React.ReactElement {
  const { selectStyles, selectContainerStyles, selectIconStyles, disabledStyles } = useStyles();
  const { className: iconClasses, ...iconProps } = icon.props;

  return (
    <div className={classnames(selectContainerStyles, disabled ? disabledStyles : {}, className)}>
      {React.cloneElement(icon, {
        // this will apply our placement styles, along with any other supplied classes
        className: classnames(selectIconStyles, iconClasses),
        // apply all other props.
        ...iconProps,
      })}
      <select
        className={selectStyles}
        required={required}
        aria-required={required}
        data-testid="NativeSelect"
        disabled={disabled}
        {...props}
      >
        {children}
      </select>
    </div>
  );
}
export default NativeSelect;
