import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

type SurveyStyleNames =
  | 'surveyHeaderContainer'
  | 'surveyToolbarContainer'
  | 'surveyTitleContainer'
  | 'pageHeaderContainer'
  | 'mainContainer'
  | 'layoutMainContainer'
  | 'questionContainer'
  | 'pageFooterContainer'
  | 'surveyFooterContainer'
  | 'sronlyFocus'
  | 'pageDescriptionOkButton'
  | 'pageNavigationContainer'
  | 'classicProgressBarTop'
  | 'customThankyouContainer'
  | 'routerDebugInfo';

type SurveyStyleProps = {
  offsetBottom?: number;
};
const useStyles = createSurveyStyles<SurveyStyleNames, SurveyStyleProps>(theme => ({
  // Document banner container
  surveyHeaderContainer: {},
  // Exit-link container
  surveyToolbarContainer: {},
  // Survey Title container
  surveyTitleContainer: {},
  // Page Header container
  pageHeaderContainer: {},
  // Main container
  mainContainer: {
    outline: 'none',
  },
  layoutMainContainer: {},
  // Form
  questionContainer: {
    paddingTop: '32px',
  },
  // Page Footer Container
  pageFooterContainer: {
    margin: [30, 0, 24],
  },
  pageNavigationContainer: {},
  // May not be used
  // Page description OK button
  sronlyFocus: {
    '&:not(:focus),&:not(:hover)': {
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(50%)',
      height: 1,
      width: 1,
      overflow: 'hidden',
      padding: 0,
    },
  },

  routerDebugInfo: {
    display: 'block',
    opacity: '0.1',
    textAlign: 'center',
    wordBreak: 'break-all',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
  },

  pageDescriptionOkButton: {
    marginTop: 40,
  },

  surveyFooterContainer: {
    padding: [0, 0, 40, 0],
    marginBottom: ({ offsetBottom = 0 }) => `${offsetBottom}px`,
  },

  classicProgressBarTop: {
    marginBottom: '36px',
  },
  customThankyouContainer: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.questionBody.fontSize,
  },
}));

export default useStyles;
