import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { WrenchTheme } from '@wds/styles';

export type TooltipStyleProps = {
  center: number;
};

const useStyles = createSurveyStyles(theme => ({
  tooltip: {
    'span[data-tooltip] &': {
      display: 'none',
    },
    [`@media (min-width: ${theme.breakpoints?.md.min})`]: {
      'span[data-tooltip]:hover &, span[data-tooltip]:focus &': {
        display: 'inline-block',
      },
    },
    position: 'absolute',
    left: ({ center }: TooltipStyleProps) => center,
    bottom: 20,
    width: 275,
    transform: 'translate(-50%, -5px)',
    textAlign: 'center',
    zIndex: 12,
    fontSize: 13,
    '&:after': {
      position: 'absolute',
      bottom: -4,
      left: '50%',
      width: 10,
      height: 10,
      backgroundColor: 'white',
      content: '""',
      marginLeft: -5,
      transform: 'rotate(45deg)',
      boxShadow: [[3, 5, 5, 'rgb(0 0 0 / 0.07)']],
      zIndex: 13,
    },
  },
  tooltipBody: {
    display: 'inline-block',
    maxWidth: 275,
    color: 'black',
    fontWeight: 300,
    fontFamily: WrenchTheme.type.fontFamily.base,
    fontStyle: 'normal',
    boxShadow: [[0, 2, 10, 0, 'rgb(0 0 0 / 0.14)']],
    background: 'white',
    padding: [[12, 16]],
    lineHeight: 1.4,
    borderRadius: 2,
    textAlign: 'left',
  },
}));

export default useStyles;
