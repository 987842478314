import { useCallback, useEffect, useState, useRef } from 'react';
import { useLayoutEffect, useAppSelectorV2 as useAppSelector, useAppDispatchV2 as useAppDispatch } from '~app/hooks';
import { selectActive, selectViews, setActive } from '~app/pages/SurveyTaking/v2/slices/oqaatSlice';
import { isAnimating, scrollIntoView } from './utils/scrollIntoView';

/** Whether a `mousedown` event has occurred within 200ms, exported to be used in OQAAT focus handling */
let clicking = false;

export default function ActiveViewScrollManager(): null {
  const active = useAppSelector(selectActive);
  const views = useAppSelector(selectViews);
  const dispatch = useAppDispatch();

  const [viewElements, setViewElements] = useState<HTMLElement[]>([]);

  useEffect(() => {
    const elements: HTMLElement[] = [];
    views.forEach(view => {
      const el = document.getElementById(`view-${view}`);
      if (el) {
        elements.push(el);
      }
    });
    setViewElements(elements);
  }, [views]);

  const findActiveAnchor = useCallback(() => {
    if (isAnimating()) {
      return;
    }
    let newIdx = -1;
    if (window.scrollY === 0) {
      newIdx = 0;
    } else {
      const winMidpoint = window.innerHeight / 2;
      viewElements.forEach((el, idx) => {
        const rect = el.getBoundingClientRect();
        if (rect.top <= winMidpoint) {
          newIdx = idx;
        }
      });
    }

    const currentAnchor = views[newIdx];

    if (currentAnchor && currentAnchor !== active) {
      dispatch(setActive(currentAnchor));
    }
  }, [views, active, viewElements, dispatch]);

  /**
   * Set `clicking` variable on click, which is used to determine OQAAT scroll behaviour
   * for focus events.
   * note: listening to `mousedown` events as they trigger before `focus` events
   */
  const handleClick = useCallback(e => {
    clicking = true;
    setTimeout(() => {
      clicking = false;
    }, 200);
  }, []);

  /**
   * Scroll to currently focused input on keydown if question is inactive
   * (makes it less confusing for the user when they've scrolled away from focused input)
   */
  const handleKeydown = useCallback(
    e => {
      /** The keys which will trigger the scroll animation - matches all alphanumeric & non-word characters */
      const keysToMatch = /^([a-z0-9]|\W|_)$/i;
      /** ViewId extracted from wrapping view element */
      const focusedViewId = document.activeElement?.closest("[id^='view-']")?.id.split('-')[1];
      if (
        keysToMatch.test(e.key) && // if the key pressed is a letter or non-word character
        focusedViewId && // if there is a focused element
        document.activeElement?.tagName === 'INPUT' && // if the focused element is an input
        views.includes(focusedViewId) && // if the focused element is inside a view
        focusedViewId !== active // if the focused view is not active
      ) {
        scrollIntoView(document.activeElement as HTMLElement);
        // activate the question being scrolled to (scroll listener is disabled during animation)
        dispatch(setActive(focusedViewId));
      }
    },
    [dispatch, views, active]
  );

  useLayoutEffect(() => {
    window.addEventListener('scroll', findActiveAnchor);
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('scroll', findActiveAnchor);
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [findActiveAnchor, handleClick, handleKeydown]);

  // Initially focus on first active view
  // Only run on first render (prevents active state being stuck on top question when scrolled up on larger screens)
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      findActiveAnchor();
      if (viewElements.length) {
        firstUpdate.current = false;
      }
    }
  }, [viewElements, findActiveAnchor]);

  return null;
}

const isOverlapping = <T extends { x: number; y: number }>(l1: T, r1: T, l2: T, r2: T, offset: number): boolean => {
  // check if one  element is above the other,
  // they cannot be overlapping
  if (r1.y - offset < l2.y || r2.y - offset < l1.y) {
    return false;
  }
  return true;
};

/**
 * Compares the target element against the visual viewport location/dimensions. Returns
 * true if the target element is within the visual viewport +/- an optional offset.
 * @param {HTMLElement} target The element we want to check is on screen
 * @param {number} [offset=0] How many pixels an element must overlap to be considered on screen
 * @returns
 */
export const isOnScreen = (target: HTMLElement | null, offset = 0): boolean => {
  if (!visualViewport || !target) {
    return false;
  }

  const dimensions = target.getBoundingClientRect();
  const { pageLeft, pageTop, width, height } = visualViewport;
  const vpPoints = {
    topLeft: { x: pageLeft, y: pageTop },
    bottomRight: {
      x: pageLeft + width,
      y: pageTop + height,
    },
  };
  const targetPoints = {
    topLeft: { x: dimensions.left, y: dimensions.top + pageTop },
    bottomRight: {
      x: dimensions.left + dimensions.width,
      y: dimensions.top + dimensions.height + pageTop,
    },
  };
  // Visible on screen if true
  return isOverlapping(targetPoints.topLeft, targetPoints.bottomRight, vpPoints.topLeft, vpPoints.bottomRight, offset);
};

/** Whether a `mousedown` event has occurred within the last 200ms */
export const isClicking = (): boolean => clicking;
