import React from 'react';
import classNames from 'classnames';
import useStyles from './useStyles';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonOrAnchor = ButtonProps | AnchorProps;

export type Props = {
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
} & ButtonOrAnchor;

function SurveyButton({
  size = 'md',
  className,
  children,
  disabled,
  'aria-disabled': ariaDisabled,
  ...props
}: Props): React.ReactElement {
  const isDisabled = disabled ?? ariaDisabled === 'true';
  const { surveyButton } = useStyles({ size });

  if ('href' in props && props.href) {
    return (
      <a
        className={classNames(surveyButton, className)}
        aria-disabled={isDisabled || undefined}
        {...(props as AnchorProps)}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      className={classNames(surveyButton, className)}
      type="button"
      aria-disabled={isDisabled || undefined}
      {...(props as ButtonProps)}
    >
      {children}
    </button>
  );
}
export default SurveyButton;
