import { ANALYTICS_WEBTYPE_TYPE } from '~app/components/amplitudeEnums';
import { BiEventsDataProps, BiEventReturnType } from '../source/app/pages/SurveyTaking/v2/types';

export const generateEventData = ({
  collector,
  survey,
  surveyOwnerPackageId,
  confirmationEmailCheckboxIsChecked,
}: BiEventsDataProps): BiEventReturnType => {
  let themeType: BiEventReturnType['themeType'] = 'undefined theme type';
  if (survey?.design?.theme?.isStockTheme) {
    themeType = 'stock_theme';
  } else if (survey?.design?.theme?.isCustomTheme) {
    themeType = 'custom_theme';
  } else if (survey?.design?.theme?.isTeamTheme) {
    themeType = 'group_theme';
  }

  return {
    collectorId: collector?.id,
    collectorType: (collector?.type ?? 'unknown').toLowerCase(),
    osFamily: '',
    language: survey?.language?.name,
    packageId: surveyOwnerPackageId ?? '-1',
    surveyId: survey?.id ?? '',
    surveyStyle: survey?.format ?? undefined,
    pageIsQuiz: survey?.isQuiz,
    numPages: survey?.pages?.totalCount,
    themeName: survey?.design?.theme?.name,
    themeVersion: survey?.design?.theme?.version ?? 'V3',
    themeType,
    respondentId: '0',
    questionTypes: undefined,
    hasLogic: undefined,
    hasProgressBar: survey?.design?.progressBar?.enabled,
    webType: ANALYTICS_WEBTYPE_TYPE.RESPWEB,
    hasConfirmationEmailCheckbox: collector?.confirmationEmailEnabled ?? false,
    confirmationEmailCheckboxIsChecked,
  };
};

export default generateEventData;
