import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';

const useStyle = createUseStyles((theme: Theme) => {
  const mediaQueryMinScreenXL = `@media (min-width: ${theme.breakpoints.xl})`;

  return {
    seoBannerPlug: {
      color: theme.palette.text.dark,
      textAlign: 'center',
      margin: '0 0.3125rem',
      padding: '1rem 0',
      '& p': {
        margin: 0,
      },
      [mediaQueryMinScreenXL]: {
        padding: '0 0.8125rem',
      },
    },
  };
});

export default useStyle;
