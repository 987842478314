import { useEffect } from 'react';
import { UseFormatViewReturn } from '../types';
import { useAppSelectorV2 as useAppSelector, useAppDispatchV2 as useAppDispatch } from '~app/hooks';
import { register, unregister } from '~app/pages/SurveyTaking/v2/slices/oqaatSlice';

export default function useFormatView<T extends HTMLElement = HTMLElement>(name: string): UseFormatViewReturn<T> {
  // computed state will only re-render if computed value changes
  const isActive = useAppSelector(({ oqaatState }) => oqaatState.active === name);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(register(name));
    return () => {
      dispatch(unregister(name));
    };
  }, [dispatch, name]);

  return { isActive };
}
