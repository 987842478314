import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

const useStyles = createSurveyStyles({
  anchorContainer: {
    fontSize: 12,
    lineHeight: '18px',
    display: 'block',
    textAlign: 'center',
    margin: '0 auto',
  },
});

export default useStyles;
