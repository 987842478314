import React, { ComponentType, ReactElement } from 'react';
import FocusTrap from './FocusTrap';

const withFocusTrap = <T extends Record<string, unknown>>(Component: ComponentType<T>) => {
  return (props: T): ReactElement => {
    return (
      <FocusTrap>
        <Component {...props} />
      </FocusTrap>
    );
  };
};

export default withFocusTrap;
