import React, { ReactElement, ReactNode, useState } from 'react';
import { Button } from '@wds/button';
import { IconXCircle } from '@wds/icons';
import { RespondentCollectionMethod, CollectorType } from '../../../lib/generatedGqlTypes';

import BannerBody from './BannerBody';
import { CallToActionType } from './CallToActionButtons';
import visibilityLogic from './helpers/visibilityLogic';
import useStyles from './useStyles';

export type SEOBannerPropsType = {
  callToActionButtons: CallToActionType[];
  children: ReactNode;
  collectorType?: CollectorType;
  collectionMethod: RespondentCollectionMethod | null;
  requestFromSearchEngine: boolean;
  closeButtonAriaText?: string;
  forceDoNotShowBanner?: boolean;
};

export default ({
  forceDoNotShowBanner = false,
  requestFromSearchEngine,
  children,
  callToActionButtons,
  collectorType,
  collectionMethod,
  closeButtonAriaText = '',
}: SEOBannerPropsType): ReactElement | null => {
  const [forceDoNotShow, setForceDoNotShow] = useState(forceDoNotShowBanner || false);

  const { seoBanner, seoBannerContents, embeddedContainer, closeXButton } = useStyles();
  if (!visibilityLogic(forceDoNotShow, requestFromSearchEngine, collectorType || '', collectionMethod)) {
    return null;
  }

  return (
    <div className={seoBanner}>
      <div className={seoBannerContents}>
        <div className={embeddedContainer}>
          <BannerBody callToActionButtons={callToActionButtons}>{children}</BannerBody>
        </div>
      </div>
      <div data-testid="SEOBanner__CloseButton" className={closeXButton}>
        <Button variant="text" color="secondary" label={closeButtonAriaText} onClick={() => setForceDoNotShow(true)}>
          <IconXCircle title="" />
        </Button>
      </div>
    </div>
  );
};
