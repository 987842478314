import { createUseStyles } from 'react-jss';

type StyleClasses = 'sharedStyles' | 'alignRight' | 'alignLeft';
type StyleProps = {
  needsBottomOffset: boolean;
};

const useStyles = createUseStyles<StyleClasses, StyleProps>({
  sharedStyles: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'center',
    padding: 5,
    backgroundColor: '#6B787F',
    position: 'fixed',
    bottom: ({ needsBottomOffset }) => (needsBottomOffset ? 48 : 15),
  },
  alignRight: {
    right: 0,
  },
  alignLeft: {
    left: 0,
  },
});

export default useStyles;
