import React, { useCallback, useMemo } from 'react';
import clsx from 'classnames';
import { deferToAfterFocusChanged } from '~app/pages/SurveyTaking/validation/delayedValidation';
import { useAppDispatchV2 as useAppDispatch } from '~app/hooks';
import { setActive, setNextActive } from '~app/pages/SurveyTaking/v2/slices/oqaatSlice';
import { isClicking, isOnScreen } from '../ActiveViewScrollManager';
import { isChildFunction, scrollIntoView, setFocusToView, focusFirstElement } from '../utils';
import { SurveyFormatViewProps } from '../types';
import useFormatView from './useFormatView';
import useStyles from './useStyles';

export function OQAATFormatView({
  name,
  noShield = false,
  autoPadding = true,
  className,
  children,
  ...props
}: SurveyFormatViewProps): React.ReactElement {
  const dispatch = useAppDispatch();

  const { isActive } = useFormatView(name);

  const { shield } = useStyles({ active: isActive, noShield, autoPadding });

  /** On click, if the current view isn't active, focus it to trigger the focus handler below */
  const handleClick = useCallback(() => {
    if (!isActive) {
      setFocusToView(name);
    }
  }, [isActive, name]);

  /** Scroll to the focus target, and activate the appropriate view. */
  const handleFocus = useCallback(
    (e: React.FocusEvent) => {
      const target = e.target as HTMLElement;
      const isQuestionTitle = target.id.startsWith('question-title-legend-');
      const isViewElement = target.id.startsWith('view-');
      // lookup the related view element in case the user clicked on the title or an input
      const targetViewElement = isViewElement ? target : target.closest<HTMLElement>('[id^=view-]');
      if (!targetViewElement) {
        return;
      }

      const isCurrentView = targetViewElement.id === `view-${name}`;
      const isTargetOnScreen = isOnScreen(target, 50);
      const isCurrentViewButOutOfViewport = isCurrentView && !isTargetOnScreen;
      if (!isActive || !isClicking() || isCurrentViewButOutOfViewport) {
        // Scroll to the target (either the question or answer) if the question is inactive,
        // OR to any target (incl. links & tooltips) if not a result of clicking,
        // OR to the question itself if the top of the question is above the viewport.
        deferToAfterFocusChanged(() => {
          if (!isQuestionTitle && !isActive && isClicking()) {
            focusFirstElement(targetViewElement);
          }
          scrollIntoView(target);
        });
      }

      if (isActive || !isCurrentView) {
        return;
      }
      if (noShield) {
        // defer to make done with error-change click work
        deferToAfterFocusChanged(() => {
          dispatch(setActive(name));
        });
        return;
      }
      dispatch(setActive(name));
    },
    [name, isActive, noShield, dispatch]
  );

  // only re-render children if their active state changes
  const content = useMemo(() => {
    if (isChildFunction(children)) {
      return children(isActive, () => dispatch(setNextActive()));
    }
    if (children) {
      return React.cloneElement(children, { active: isActive, onSubmit: () => dispatch(setNextActive()) });
    }
    return null;
  }, [children, dispatch, isActive]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      onFocus={handleFocus}
      onMouseDown={noShield || isActive ? undefined : e => e.preventDefault()}
      onClick={noShield ? undefined : handleClick}
      className={clsx(shield, className)}
      {...props}
      id={`view-${name}`}
      data-testid={`OQAATView__${name}`}
      tabIndex={-1}
    >
      {content}
    </div>
  );
}

export default OQAATFormatView;
