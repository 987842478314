import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';

const useStyle = createUseStyles((theme: Theme) => {
  const mediaQueryMinScreenXL = `@media (min-width: ${theme.breakpoints.xl}px)`;

  return {
    seoBannerLogoSmallScreen: {
      width: theme.iconSize.xl,

      [mediaQueryMinScreenXL]: {
        display: 'none',
      },
    },
    seoBannerLogoLargeScreen: {
      display: 'none',
      [mediaQueryMinScreenXL]: {
        display: 'inline-block',
        width: 161,
        height: 24,
      },
    },
  };
});

export default useStyle;
