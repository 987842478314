import { JssStyle } from 'jss';
import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { SurveyTheme as SurveyThemeType } from '@sm/webassets/SurveyTheme/types';

import { isPartial, isCenter, isPartialRight, isBanner, getLayoutSize, isLeftOrRight } from '~app/helpers/themeLayout';

type LayoutStyleClasses =
  | 'layoutContainer'
  | 'layoutPartialContainer'
  | 'layoutContentContainer'
  | 'layoutTopContainer'
  | 'layoutMainContainer'
  | 'layoutLogoContainer'
  | 'layoutToolbarContainer';

type LayoutStyleProps = { isRTL: boolean };

/**
 * The current use of breakpoints do NOT match production breakpoints, so
 * they are as close as possible.
 */
const partialSize = (theme: SurveyThemeType): JssStyle => {
  const fullDimensions = {
    width: '0%',
    height: '100%',
    minHeight: 'auto',
  };
  return {
    full: fullDimensions,
    fullLeft: fullDimensions,
    fullRight: fullDimensions,
    top: {
      width: 'auto',
    },
    half: {
      width: '0%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '34%',
      },
      [`@media (min-width: ${theme.breakpoints?.md.min})`]: {
        width: '50%',
      },
    },
    third: {
      width: '0%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '34%',
      },
    },
  }[getLayoutSize(theme.layout)];
};
const imageSize = (theme: SurveyThemeType): JssStyle => {
  const base = partialSize(theme);
  const size = !isPartial(theme.layout) ? 'full' : getLayoutSize(theme.layout);
  return {
    ...base,
    ...(size === 'full' || size === 'top' ? { width: '100%' } : {}),
  };
};
const contentSize = (theme: SurveyThemeType): JssStyle => {
  return {
    full: {
      width: '100%',
    },
    fullLeft: {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '66%',
        marginRight: '34%',
      },
      [`@media (min-width: ${theme.breakpoints?.md.min})`]: {
        width: '50%',
        marginRight: '50%',
      },
    },
    fullRight: {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '66%',
        marginLeft: '34%',
      },
      [`@media (min-width: ${theme.breakpoints?.md.min})`]: {
        width: '50%',
        marginLeft: '50%',
      },
    },
    top: {
      width: '100%',
    },
    half: {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '66%',
      },
      [`@media (min-width: ${theme.breakpoints?.md.min})`]: {
        width: '50%',
      },
    },
    third: {
      width: '100%',
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        width: '66%',
      },
    },
  }[getLayoutSize(theme.layout)];
};
const mainPadding = (theme: SurveyThemeType): JssStyle => {
  return isLeftOrRight(theme.layout) && !isBanner(theme.layout)
    ? {
        padding: [40, 24, 0, 24],
        [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
          padding: [40, 40, 0, 40],
        },
        [`@media (min-width: ${theme.breakpoints?.xl.min})`]: {
          padding: [80, 80, 0, 80],
        },
      }
    : {
        [`@media (min-width: ${theme.breakpoints?.sm.max})`]: {
          padding: [80, 140, 0, 140],
        },
        [`@media (max-width: ${theme.breakpoints?.sm.max})`]: {
          padding: [80, 80, 0, 80],
        },
        [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
          padding: [40, 40, 0, 40],
        },
      };
};

const rtlToolbar = (theme: SurveyThemeType): JssStyle => {
  return {
    paddingRight: ({ isRTL }) => (isRTL ? 0 : 12),
    paddingLeft: ({ isRTL }) => (isRTL ? 12 : 0),
    '& > *:not(:first-child)': {
      marginLeft: ({ isRTL }) => (isRTL ? 0 : 10),
      marginRight: ({ isRTL }) => (isRTL ? 10 : 0),
    },
  };
};

const useStyles = createSurveyStyles<LayoutStyleClasses, LayoutStyleProps>((theme: SurveyThemeType) => {
  const imgOverlayColor =
    theme?.surveyPage?.backgroundImage !== undefined || isPartial(theme.layout)
      ? theme?.surveyPage?.overlayColor
      : theme?.primaryBackgroundColor;
  const partialBgImage = [`linear-gradient(${imgOverlayColor}, ${imgOverlayColor})`, theme?.surveyPage?.backgroundImage]
    .filter(o => typeof o !== 'undefined')
    .join(', ');

  return {
    layoutContainer: {
      direction: ({ isRTL }) => (isRTL ? 'rtl' : 'ltr'),
      display: 'flex',
      flexDirection: isBanner(theme.layout) ? 'column' : 'row',
      minHeight: '100vh',
    },
    layoutPartialContainer: {
      position: 'relative',
      order: isPartialRight(theme.layout) ? 2 : 1,
      minHeight: '40px',
      padding: isBanner(theme.layout) ? [40, 0] : {},
      ...partialSize(theme),

      '&:before': {
        display: 'block',
        position: isBanner(theme.layout) ? 'absolute' : 'fixed',
        content: '""',
        zIndex: -10,
        top: 0,
        height: '100%',
        background: {
          position: '50% 50%',
          size: 'cover',
          image: partialBgImage,
        },
        ...imageSize(theme),
      },
    },
    layoutContentContainer: {
      boxSizing: 'border-box',
      fontFamily: theme?.questionTitle?.fontFamily,
      minHeight: '100%',
      flexGrow: isBanner(theme.layout) ? 1 : 0,
      backgroundColor: isPartial(theme.layout) ? theme?.primaryBackgroundColor : undefined,
      order: isPartialRight(theme.layout) ? 1 : 2,
      ...contentSize(theme),
    },
    layoutTopContainer: {},
    layoutMainContainer: {
      maxWidth: '896px',
      margin: isCenter(theme.layout) || isBanner(theme.layout) ? '0 auto' : {},
      ...mainPadding(theme),
    },

    layoutLogoContainer: {
      padding: isBanner(theme.layout) ? [40, 80] : [8, 40, 0, 40],
    },
    layoutToolbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      // May change depend on RTL?
      justifyContent: 'flex-end',
      alignItems: 'center',
      boxSizing: 'border-box',

      paddingTop: 12,
      width: '100%',
      ...rtlToolbar(theme),
    },
  };
});

export default useStyles;
