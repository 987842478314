import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

const useStyles = createSurveyStyles(theme => {
  return {
    gridRow: {
      display: 'grid',
      gap: '20px',
      maxWidth: '100%',
      marginBottom: '40px',
      [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
        gridTemplateColumns: 'minmax(auto, 100%)',
      },
    },
    gridItem: {
      boxSizing: 'border-box',
      // applies to all maxQuestionsPerRow - just show 1 per row for small screens
      [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
        paddingRight: `0 !important`,
        width: '100% !important',
      },
    },

    // Each row configuration has its own static class to work around a
    // JSS / media - query bug when combining variable props and media-queries
    // see RAWR-735

    gridItem1Item: {
      gridTemplateColumns: 'minmax(0, 1fr)',
      width: '100%',
    },
    gridItem2Items: {
      [`@media (min-width: ${theme.breakpoints?.sm.min})`]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
    },
    gridItem3Items: {
      [`@media (min-width: ${theme.breakpoints?.sm.min}) and (max-width: ${theme.breakpoints?.md.max})`]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
      [`@media (min-width: ${theme.breakpoints?.lg.min})`]: {
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      },
    },
    gridItem4Items: {
      [`@media (min-width: ${theme.breakpoints?.sm.min}) and (max-width: ${theme.breakpoints?.md.max})`]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      },
      [`@media (min-width: ${theme.breakpoints?.lg.min})`]: {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      },
    },
  };
});

export default useStyles;
