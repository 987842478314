import React, { ReactElement } from 'react';

import { Box } from '@wds/box';
import { LogoWithText } from '@sm/webassets/Logos';
import { IconLogoGoldie } from '@wds/icons';

import useStyle from './useStyles';

export const GOLDIE_ICON = 'Logo__GoldieIcon';
export const GOLDIE_ICON_WITH_TEXT = 'Logo__GoldieIcon_with_text';

export default (): ReactElement => {
  const { seoBannerLogoLargeScreen, seoBannerLogoSmallScreen } = useStyle();

  return (
    <Box display="flex" flexDirection="column" flexAlign="center" flexJustify="center">
      <div data-testid={GOLDIE_ICON_WITH_TEXT} className={seoBannerLogoLargeScreen}>
        <LogoWithText color="primary" />
      </div>
      <div data-testid={GOLDIE_ICON} className={seoBannerLogoSmallScreen}>
        <IconLogoGoldie title="SurveyMonkey Logo" size="xl" color="primary" />
      </div>
    </Box>
  );
};
