import React from 'react';
import { SurveyFormatViewProps } from '../types';

import { isChildFunction } from '../utils';

export function ClassicFormatView({
  name,
  noShield,
  className,
  autoPadding,
  children,
  ...props
}: SurveyFormatViewProps): React.ReactElement {
  const content = isChildFunction(children) ? children(true, () => {}) : children;

  return (
    <div className={className} id={`view-${name}`} {...props} data-testid={`ClassicView__${name}`}>
      {content}
    </div>
  );
}

export default ClassicFormatView;
