import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

export const useStyles = createSurveyStyles<'shield', { active: boolean; noShield: boolean; autoPadding: boolean }>(
  theme => ({
    shield: {
      opacity: ({ active, noShield }) => (!!noShield || active ? 1 : 0.5),
      paddingBottom: ({ autoPadding }) => (autoPadding ? '80px' : 0),
      [`@media (max-width: ${theme.breakpoints?.xs.max})`]: {
        paddingBottom: ({ autoPadding }) => (autoPadding ? '270px' : 0),
      },
      // separate title and body elements to allow distinction in the focus event
      '& fieldset > *:not([id^="question-title-legend-"]), & fieldset > [id^="question-title-legend-"] > *': {
        pointerEvents: ({ active, noShield }) => (active || noShield ? 'auto' : 'none'),
      },
      cursor: ({ active, noShield }) => (active || noShield ? 'auto' : 'pointer'),
      // prevent default browser outline for the focused view
      outline: 'none',
    },
  })
);

export default useStyles;
