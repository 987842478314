import React from 'react';
import classNames from 'classnames';
import useStyles, { ProgressBarStyleProps } from './useStyles';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface Props extends React.HTMLAttributes<HTMLDivElement>, ProgressBarStyleProps {}

const ProgressBar: React.FC<Props> = ({
  value,
  borderColor = '#000',
  progressColor = '#000',
  emptyColor = 'transparent',
  radius = 5,
  height = 8,
  className,
  ...otherProps
}) => {
  /** Limit `value` to the range between `0` and `100` */
  const boundedValue = Math.max(0, Math.min(value, 100));

  const { container, bar } = useStyles({
    value: boundedValue,
    borderColor,
    progressColor,
    emptyColor,
    radius,
    height,
  });

  return (
    <div
      role="progressbar"
      aria-valuenow={boundedValue}
      aria-valuemin={0}
      aria-valuemax={100}
      className={classNames(container, className)}
      {...otherProps}
    >
      <div className={bar} />
    </div>
  );
};

export default ProgressBar;
