import { createUseStyles } from 'react-jss';
import { Theme } from '@wds/styles';

const useStyles = createUseStyles((theme: Theme) => ({
  modalOverlay: {
    zIndex: 999,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  modal: {
    position: 'relative',
    width: '85vw',
    minHeight: '20vw',
    marginBottom: 20,
    background: theme.palette.background.light,
    borderRadius: 2,
  },
  closeBtn: {
    position: 'absolute',
    cursor: 'pointer',
    background: theme.palette.transparent,
    right: 0,
    border: 0,
    padding: 8,
    marginTop: 0,
    color: 'black',
    zIndex: 105,
  },
  tooltipText: {
    color: theme.palette.text.dark,
    fontFamily: theme.type.fontFamily.base,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '1.4',
    padding: [28, 12, 16],
  },
}));

export default useStyles;
