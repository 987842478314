import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { SurveyLogo } from '~lib/generatedGqlTypes';

type StyleClassNames = 'logoContainer' | 'logoWrapper' | 'logoImage';
type StyleProps = Omit<SurveyLogo, 'enabled' | 'image'> & { isRTL: boolean };

// Logo alignment comes in as `verticalAlignment`, and the keys represent
// values available from GraphQL API (SurveyLogoAlignment).  Presently, the
// only options to choose from are: CENTER, LEFT_TOP, RIGHT_TOP.
const surveyLogoAlignment: Record<string, { justify: string; align: string }> = {
  CENTER: { justify: 'center', align: 'center' },
  LEFT_TOP: { justify: 'flex-start', align: 'flex-start' },
  RIGHT_TOP: { justify: 'flex-end', align: 'flex-start' },
  // Here for other values when implemented
  BASELINE: { justify: 'flex-start', align: 'baseline' },
  BOTTOM: { justify: 'center', align: 'flex-end' },
  LEFT_MIDDLE: { justify: 'flex-start', align: 'center' },
  RIGHT_MIDDLE: { justify: 'flex-end', align: 'center' },
  TOP: { justify: 'center', align: 'flex-start' },
};

const isActualSize = (size: string | null): boolean => {
  // presently, `null` is returned in place of 'ACTUAL', here for any regression.
  return size === null || size === 'ACTUAL';
};

const useStyles = createSurveyStyles<StyleClassNames, StyleProps>(theme => ({
  logoContainer: {
    display: 'flex',
    flexDirection: ({ isRTL }) => (isRTL ? 'row-reverse' : 'row'),
    width: '100%',
    justifyContent: ({ verticalAlignment }) => verticalAlignment && surveyLogoAlignment[verticalAlignment].justify,
    alignItems: ({ verticalAlignment }) => verticalAlignment && surveyLogoAlignment[verticalAlignment].align,
  },
  logoWrapper: {
    width: ({ size, width }) => (size && !isActualSize(size) ? width : 'auto'),
    height: ({ size, height }) => (size && !isActualSize(size) ? height : 'auto'),
  },
  logoImage: {
    width: '100%',
    height: '100%',
    [`@media only screen and (max-width: ${theme.breakpoints?.md.max})`]: {
      height: 'auto',
    },
  },
}));

export default useStyles;
