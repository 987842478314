import { createUseStyles } from 'react-jss';
import { Theme as WrenchTheme } from '@wds/styles';
import { SurveyTheme as SurveyThemeType } from '@sm/webassets/SurveyTheme/types';

type StyleClasses = 'container' | 'text' | 'logo' | 'imageLink' | 'logoContainer';
type StyleProps = {
  surveyTheme: SurveyThemeType;
  logoColor: string;
  textColor: string;
};

const useStyles = createUseStyles<StyleClasses, StyleProps, WrenchTheme>(wrenchTheme => {
  const fontFamily = wrenchTheme.type.fontFamily.base;
  // smallest font-size is 14px in wrenchTheme.type.fontSize.bodySm (cannot use)
  const fontSize = '12px';
  const lineHeight = '15px';
  return {
    container: {
      margin: '0 auto',
      padding: 0,
      textAlign: 'center',
    },
    text: ({ textColor }) => ({
      display: 'inline-block',
      textAlign: 'center',
      padding: 0,
      width: '100%',
      margin: '0',
      color: textColor ?? '',
      fontFamily,
      fontSize,
      lineHeight,
    }),
    logo: ({ logoColor }) => ({
      width: '100%',
      height: '100%',
      color: logoColor,
      fill: logoColor,
    }),
    logoContainer: {
      width: 157,
      height: 23,
      margin: [2, 0],
      display: 'block',
    },
    imageLink: {
      display: 'inline-flex',
      justifyContent: 'center',
      padding: [0, 25],
    },
  };
});

export default useStyles;
