import React, { useContext } from 'react';
import classNames from 'classnames';
import { StaticContext } from '@sm/webassets/StaticContext';

import { IconLock } from '@wds/icons';
import { Tooltip } from '@wds/tooltip';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import { SurveyLink } from '../SurveyLink';

import { getThemeLayoutType } from '~app/helpers/themeLayout';
import getLinkBasedOnLangCode from '~app/helpers/languageLinks';

import useStyles from './useStyles';

type FooterPrivacyIconProps = {
  /**
   * Show the privacy icon just above PersistentProgressBar when mode is OQAAT and Progress bar is enabled
   * Otherwise, show the privacy icon at the bottom of the page
   */
  needsBottomOffset: boolean;
};

const FooterPrivacyIcon: React.FC<FooterPrivacyIconProps> = ({ needsBottomOffset }) => {
  const { layout: layoutName } = useSurveyTheme();
  const { isPartialRight } = getThemeLayoutType(layoutName);

  const { alignRight, alignLeft, sharedStyles } = useStyles({ needsBottomOffset });

  const privacyIconPlacement = isPartialRight ? alignLeft : alignRight;
  const tooltipPlacement = isPartialRight ? 'right' : 'left';

  const {
    environment: { domain, languageCode },
  } = useContext(StaticContext);

  const PRIVACY_BASICS_URL = getLinkBasedOnLangCode({
    languageCode,
    domain,
    path: '/mp/legal/privacy/?ut_source=survey_pp',
  });

  return (
    <div
      className={classNames(privacyIconPlacement, sharedStyles)}
      id="privacy-icon-lock"
      data-testid="FooterPrivacyIcon__PrivacyIcon"
    >
      <Tooltip text="Privacy & Cookie Notice" placement={tooltipPlacement}>
        <SurveyLink rel="noreferrer" href={PRIVACY_BASICS_URL} target="_blank" aria-label="SurveyMonkey Privacy Icon">
          <IconLock color="light" size="md" title="Privacy & Cookie Notice" />
        </SurveyLink>
      </Tooltip>
    </div>
  );
};

export default FooterPrivacyIcon;
