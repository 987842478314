import { SurveyMode, SurveyView } from '../v2/slices/surveySlice';

export const isThankYouView = (view: SurveyMode): boolean => {
  return view === SurveyView.ThankYou;
};

export const isSurveyTakingView = (view: SurveyMode): boolean => {
  return view === SurveyView.Taking;
};

export default { isThankYouView, isSurveyTakingView };
