import React, { useContext } from 'react';
import { L10NContext } from '@sm/intl';

import { RichText } from '../../core/RichText';
import useSurveyStyles from './useStyles';

/** require `number` if `numbered` is `true` */
type NumberProps =
  | {
      numbered?: false;
      number?: number;
    }
  | {
      numbered: true;
      number: number;
    };

export type Props = {
  id?: Element['id'];
  text: string;
  element?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & NumberProps &
  React.HTMLAttributes<HTMLHeadingElement>;

const PageTitle: React.FC<Props> = ({ id, element: Element = 'h2', text, numbered = false, number, ...otherProps }) => {
  const { isRTL } = useContext(L10NContext);
  const { pageTitle, questionNumber } = useSurveyStyles({ isRTL });
  return (
    <Element id={id} data-testid="PageTitle__Title" className={pageTitle} {...otherProps}>
      {numbered && <span className={questionNumber}>{number}.</span>}
      <RichText element="span" text={text} />
    </Element>
  );
};

export default PageTitle;
