import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

export type NativeSelectOptionProps = React.OptionHTMLAttributes<HTMLOptionElement>;

const useStyles = createUseStyles({
  selectOption: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
});

function NativeSelectOption({ children, className, ...props }: NativeSelectOptionProps): React.ReactElement {
  const { selectOption } = useStyles();
  return (
    <option data-testid="NativeSelectOption" className={classNames(selectOption, className)} {...props}>
      {children}
    </option>
  );
}

export default NativeSelectOption;
