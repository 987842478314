import { createUseStyles } from 'react-jss';
import { Theme as WrenchTheme } from '@wds/styles';

const useStyles = createUseStyles((theme: WrenchTheme) => ({
  skipLinks: {
    fontFamily: theme.type.fontFamily.base,
    fontSize: theme.type.fontSize.body,
    fontWeight: theme.type.fontWeight.medium,
    top: 0,
    left: 0,
    zIndex: 999999,
    overflow: 'hidden',

    position: 'absolute',
    clip: 'rect(0 0 0 0)',
    width: '1px',
    height: '1px',
    padding: [0],

    '&:focus-within': {
      position: 'fixed',
      clip: 'auto',
      width: '100%',
      height: 'auto',
      padding: [10],
    },
    backgroundColor: '#fff',

    // add spacing between multiple skip links
    '& > a': {
      marginRight: '8px',
      color: theme.palette.text.link,
      textDecoration: 'underline',
      '&:hover, &:focus': {
        outlineOffset: 2,
        outline: `2px solid ${theme.palette.text.link}`,
      },
    },
  },
}));

export default useStyles;
