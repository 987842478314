import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

type StyleClasses = 'text' | 'routerDebugInfo';
type StyleProps = {
  textColor?: string;
};

const useStyles = createSurveyStyles<StyleClasses, StyleProps>(() => ({
  text: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    color: ({ textColor }) => textColor ?? '',
  },
  routerDebugInfo: {
    display: 'block',
    opacity: '0.1',
    textAlign: 'center',
    wordBreak: 'break-all',
  },
}));

export default useStyles;
