import { deferToAfterFocusChanged } from '~app/pages/SurveyTaking/validation';
import { scrollIntoView } from './scrollIntoView';

/** css selector for form elements that can be focused within a question-type */
const focusableElements = [
  'button',
  'textarea',
  'input:not([type="hidden"])',
  'select',
  '[tabindex]:not([tabindex="-1"])',
]
  .map(el => `${el}:not([disabled]):not([aria-hidden]):not([id^="question-title-legend-"] *)`) // ignore question-header, disabled and aria hidden
  .join(', ');

/** focuses first focusable form element in `baseElement` (ignores anchors etc in the head) */
export const focusFirstElement = (baseElement: HTMLElement): void => {
  const firstFocusableEl = baseElement.querySelector<HTMLElement>(focusableElements);
  firstFocusableEl?.focus({ preventScroll: true });
};

/**
 * Set focus to the view with the given name.
 *
 * Error-animation offset assumes it contains the top-most remaining error
 *
 * @param viewName The name of the view to find the first input to set focus to
 * @param focusOnInput if true the first (form) input will be focused
 * @param viewNamesInOrder All views of the page in order to identify which ones are above `viewName`
 * (only needed for classic mode)
 * @param isClassic if true handles scrolling and align to the top of the question
 */
export const setFocusToView = (
  viewName: string,
  focusOnInput = false,
  viewNamesInOrder: string[] = [],
  isClassic = false
): void => {
  const viewEl = document.getElementById(`view-${viewName}`);
  if (!viewEl) {
    return;
  }
  if (focusOnInput) {
    focusFirstElement(viewEl);
  } else {
    viewEl.focus({ preventScroll: true });
  }
  if (!isClassic) {
    return; // OQAAT is done here
  }
  // Classic only

  /** views above question with error to focus */
  const viewsAbove = viewNamesInOrder.slice(0, viewNamesInOrder.indexOf(viewName));
  /** combined size in px of errors above that are in the process of collapsing */
  let errorsAboveOffset = 0;

  if (viewsAbove.length > 0) {
    // query for all errors above view to focus
    const errorRowsAbove = document.querySelectorAll<HTMLElement>(viewsAbove.map(v => `#error-row-${v}`).join(', '));
    errorsAboveOffset = Array.from(errorRowsAbove).reduce((offeset, el) => offeset + (el.offsetHeight || 0), 0);
  }
  const errorRow = document.getElementById(`error-row-${viewName}`);

  deferToAfterFocusChanged(() => {
    scrollIntoView(errorRow ?? viewEl, true, errorsAboveOffset);
  });
};

export default setFocusToView;
