import React, { useMemo } from 'react';

/**
 * @todo Add @sm/question-definitions as an explicit dependency; or, import
 * from explicit dependency source. i.e. @sm/question-widgets
 *
 * As a best-practice, dependencies of a dependency should not be imported.
 */
import { ExternalQuestionRespondentSurvey, QuestionError } from '@sm/question-definitions';
import {
  SingleQuestionController,
  ExternalQuestion,
  SingleQuestionControllerProps,
  RespondentDisplayOptions,
  RespondentAnswerTypes,
} from '@sm/question-widgets/respondent-survey';
import { Maybe, SurveyQuestionNumberingType } from '@sm/question-definitions/@types/lib/generatedGqlTypes';
import { SurveyFormatView } from '~app/components/Survey/SurveyFormat';
import groupQuestionsWithLayoutConfig from './utils';
import { QuestionRowLayout } from '../QuestionRowLayout';
import { registerValidator, unregisterValidator } from '../../validation';
import transformFromSurveyErrors from '../../errors/transformFromSurveyErrors';

import { useAppSelectorV2 as useAppSelector } from '~app/hooks';
import { selectErrorsById } from '../../v2/slices/errorsSlice';
import { selectAnswerById } from '../../v2/slices/surveySlice';

import { QuestionAnswers } from '../../v2/types';

export type Props = {
  questions?: ExternalQuestion[];
  answers?: QuestionAnswers;
  questionNumberType?: Maybe<SurveyQuestionNumberingType> | undefined;
  /** Checks if the question coming in is OQAAT is default set to true */
  isOQAAT?: boolean;
};

type SingleQuestionControllerWrapperProps = Pick<SingleQuestionControllerProps, 'question'> &
  Pick<Props, 'questionNumberType'> &
  Omit<RespondentDisplayOptions, 'errors'>;

/** Wrapper to flatten `displayOptions` */
function SingleQuestionControllerWrapper({
  question,
  answer,
  questionNumberType,
  ...props
}: SingleQuestionControllerWrapperProps): React.ReactElement {
  const errors = useAppSelector(state => selectErrorsById(state, question.id));
  const answerFromState = useAppSelector(state => selectAnswerById(state, question.id));

  const displayOptions: RespondentDisplayOptions = {
    errors: transformFromSurveyErrors<QuestionError>(errors),
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    answer: (answer ?? answerFromState) as RespondentAnswerTypes | undefined,
    registerValidator,
    unregisterValidator,
    ...props,
  };
  const { position } = question as ExternalQuestionRespondentSurvey;
  const questionNumber = questionNumberType === 'PAGE' ? position.inPage : position.label;

  const adjustedQuestion = {
    ...question,
    position: {
      ...position,
      label: `${questionNumber}`,
    },
  };
  return <SingleQuestionController question={adjustedQuestion} displayOptions={displayOptions} />;
}

function QuestionList(
  this: void,
  { questions = [], answers, isOQAAT = true, questionNumberType }: Props
): React.ReactElement {
  const groups = useMemo(() => groupQuestionsWithLayoutConfig(questions), [questions]);
  return (
    <>
      {groups.map(group => (
        <QuestionRowLayout
          isOQAAT={isOQAAT}
          maxQuestionsPerRow={isOQAAT ? 1 : 4}
          key={`group_${group.map(g => g.id).concat('-')}`}
        >
          {group.map(question => (
            <SurveyFormatView name={question.id} key={question.id}>
              {/** `active` and `onSubmit` get automatically applied by `SurveyFormatView` */}
              <SingleQuestionControllerWrapper
                questionNumberType={questionNumberType}
                question={question}
                answer={(answers?.[question.id] as RespondentAnswerTypes) ?? undefined}
              />
            </SurveyFormatView>
          ))}
        </QuestionRowLayout>
      ))}
    </>
  );
}

export default QuestionList;
