import classNames from 'classnames';
import React from 'react';

import useStyles from './useStyles';

export type Props = {
  children: React.ReactNode;
  maxQuestionsPerRow?: number;
  isOQAAT?: boolean;
};

function QuestionRowLayout({ children, maxQuestionsPerRow = 4, isOQAAT = true }: Props): React.ReactElement {
  const { gridItem, gridRow, gridItem1Item, gridItem2Items, gridItem3Items, gridItem4Items } = useStyles();

  if (isOQAAT) {
    return <>{children}</>;
  }

  const numOfQuestions = React.Children.count(children);
  const itemCount = Math.min(numOfQuestions, maxQuestionsPerRow);

  const gridItemClasses = classNames({
    [gridRow]: true,
    [gridItem1Item]: itemCount === 1 || !itemCount,
    [gridItem2Items]: itemCount === 2,
    [gridItem3Items]: itemCount === 3,
    [gridItem4Items]: itemCount === 4,
  });

  return (
    <div data-testid="QuestionRowLayout__Test" className={gridItemClasses}>
      {React.Children.map(children, child => (
        <div className={gridItem}>{child}</div>
      ))}
    </div>
  );
}

export default QuestionRowLayout;
