import React, { ReactElement } from 'react';
import { Modal } from '@wds/modal';
import { IconWarningFilled } from '@wds/icons';
import { defineMessages, T, t } from '@sm/intl';
import { Button } from '@wds/button';
import useStyles from './useStyles';
import { withFocusTrap } from '~app/components/core/FocusTrap';

const DEFAULT_COPY = defineMessages({
  WARNING_ICON: {
    id: 'Survey.ModifiedSurveyDialog.WarningIcon',
    defaultMessage: 'Warning',
    description: '[Type: Paragraph][Vis: low] - Alt text for warning icon',
  },
  MODIFIED_HEADER: {
    id: 'Survey.ModifiedSurveyDialog.ModifiedHeader',
    defaultMessage: 'This survey was modified by its creator',
    description: '[Type: Header][Vis: High] - Header for modified dialog',
  },
  MODIFIED_DESCRIPTION: {
    id: 'Survey.ModifiedSurveyDialog.ModifiedDescription',
    defaultMessage:
      'For questions on the page you’re currently on, you’ll need to re-submit your answers. Your answers for previous pages are saved (you’ll see them when you click OK). Survey creators usually make changes only when it’s vital to the data, so thank you for your patience.',
    description: '[Type: Paragraph][Vis: medium] - Description text for modified survey modal',
  },
  BUTTON: {
    id: 'Survey.ModifiedSurveyDialog.Button',
    defaultMessage: 'OK',
    description: '[Type: Button][Vis: High] - Button text',
  },
  CLOSE_DIALOG: {
    id: 'Survey.ModifiedSurveyDialog.CloseDialog',
    defaultMessage: 'Close dialog',
    description: '[Type: Button][Vis: High] - Label text for accessibility',
  },
});

export type ModifiedSurveyDialogProps = {
  onConfirmClick: VoidFunction;
};

function ModifiedSurveyDialog({ onConfirmClick }: ModifiedSurveyDialogProps): ReactElement {
  const { text } = useStyles();
  return (
    <Modal show dismissible={false} size="md" data-testid="ModifiedSurveyDialog" role="alertdialog">
      <Modal.Header
        header={
          <>
            <IconWarningFilled title={t(DEFAULT_COPY.WARNING_ICON)} color="darkMuted" />{' '}
            <T desc={DEFAULT_COPY.MODIFIED_HEADER} />
          </>
        }
      />
      <Modal.Body>
        <p className={text}>
          <T desc={DEFAULT_COPY.MODIFIED_DESCRIPTION} />
        </p>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button label={t(DEFAULT_COPY.CLOSE_DIALOG)} onClick={onConfirmClick} buttonType="button">
          <T desc={DEFAULT_COPY.BUTTON} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withFocusTrap(ModifiedSurveyDialog);
