import React, { useContext } from 'react';
import { L10NContext } from '@sm/intl';
import useStyles from './useStyles';
import { SurveyLogoSize, SurveyLogoAlignment } from '~lib/generatedGqlTypes';

export type Props = {
  size?: SurveyLogoSize | null;
  height?: number | null;
  width?: number | null;
  imageUrl?: string | null;
  verticalAlignment?: SurveyLogoAlignment | null;
  description?: string | null;
} & React.HTMLAttributes<HTMLDivElement>;

export function Logo({
  id,
  size,
  height,
  width,
  imageUrl,
  verticalAlignment,
  description: altText,
  ...props
}: Props): React.ReactElement {
  const { isRTL } = useContext(L10NContext);
  const { logoContainer, logoWrapper, logoImage } = useStyles({
    verticalAlignment: verticalAlignment ?? 'LEFT_TOP',
    size: size ?? 'ACTUAL',
    width: width ?? 0,
    height: height ?? 0,
    isRTL,
  });

  return (
    <div id={id} className={logoContainer} {...props}>
      <div className={logoWrapper}>
        <img src={imageUrl ?? ''} alt={altText ?? ''} className={logoImage} />
      </div>
    </div>
  );
}

export default Logo;
