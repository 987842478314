import { ExternalQuestion, RespondentAnswerTypes } from '@sm/question-widgets/respondent-survey';
import { ExternalDateTime, MatrixAnswerType, QuestionTypeVariant, ResponseValue } from '@sm/question-definitions';
import { DateTimeAnswerPayload } from '@sm/question-ui/respondent-survey';
import { QuestionAnswers } from '~app/pages/SurveyTaking/v2/types';
import { mapImplicitResponse } from './mapImplicitResponse';

import { InputMaybe, QuestionFieldValidationType, RespApiAnswerInput } from '~lib/generatedGqlTypes';

/** Fills answers and adds "other" value */
const mapMultipleChoiceAnswers = (
  values: ResponseValue<string>[],
  responseBase: Partial<RespApiAnswerInput>,
  variant?: String
): Partial<RespApiAnswerInput>[] =>
  values.reduce<Partial<RespApiAnswerInput>[]>((acc, { id: answerId, type, value }) => {
    if (answerId) {
      const response: Partial<RespApiAnswerInput> = { ...responseBase, answerId };
      if (type === 'ANSWER' || type === 'COMMENT' || variant === 'MANY_FIELDS') {
        response.answerText = value;
      }
      return [...acc, response];
    }
    return acc;
  }, []);

/** Fills answers and adds "other" value */
const mapStarRatingAnswers = (
  values: ResponseValue<string>[],
  responseBase: Partial<RespApiAnswerInput>
): Partial<RespApiAnswerInput>[] =>
  values.reduce<Partial<RespApiAnswerInput>[]>((acc, { id: answerId, type, value }) => {
    if (answerId) {
      const response: Partial<RespApiAnswerInput> = { ...responseBase, answerId };
      if (type === 'COMMENT') {
        response.answerText = value;
      } else {
        response.columnId = value as InputMaybe<string>;
      }
      return [...acc, response];
    }
    return acc;
  }, []);

/** Formats `date` as US (`MM/DD/YYYY`) format */
const toUSDate = (
  date: string | undefined,
  validationType: QuestionFieldValidationType | undefined
): string | undefined => {
  if (!date) {
    return undefined;
  }
  if (validationType !== 'DATE_INTL') {
    return date ?? undefined;
  }
  const [d, m, y] = date.split(/[/.-]/);
  return `${m}/${d}/${y}`;
};

/** Formats response for DateTime */
const mapDateTimeAnswers = (
  // values: ResponseValue<DateTimeAnswerPayload>[],
  values: ResponseValue<DateTimeAnswerPayload>[],
  responseBase: Partial<RespApiAnswerInput>,
  validationType: QuestionFieldValidationType | undefined
): Partial<RespApiAnswerInput>[] =>
  values.reduce<Partial<RespApiAnswerInput>[]>((acc, { id: answerId, value: { date, hour, minute, period } }) => {
    if (answerId) {
      // ResponsesAPI expects Datetime to be always be send in US (M/D/Y) format
      const formattedDate = toUSDate(date, validationType);
      const formattedTime = hour && minute && period ? `${hour}:${minute} ${period}` : undefined;

      return [...acc, { ...responseBase, answerId, answerText: [formattedDate, formattedTime].join(' ').trim() }];
    }
    return acc;
  }, []);

/**
 * Function that maps an individual answer to the shape expected by the API, returned as an array.
 * Reference - expected shapes for each question type:
 * @see https://code.corp.surveymonkey.com/pages/devmonkeys/ResponsesAPI/?shell#the-answer-object
 */
const mapAnswerResponse = (
  { values }: Pick<RespondentAnswerTypes, 'values'>,
  // `RespondentAnswerTypes` does not contain family, variant for presentational or unanswered questions
  externalQuestion: ExternalQuestion,
  responseBase: Partial<RespApiAnswerInput>
  // we want to keep this in one lookup for now
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Partial<RespApiAnswerInput>[] => {
  const { family, variant } = externalQuestion;
  switch (family) {
    // single textbox & comment box
    case 'OPEN_ENDED': {
      switch (variant as QuestionTypeVariant['OPEN_ENDED']) {
        case 'SINGLE_LINE':
        case 'ESSAY': {
          if (!values[0]?.value) {
            return [];
          }
          return [{ ...responseBase, answerText: values[0].value as InputMaybe<string> }];
        }
        case 'MANY_FIELDS':
          return mapMultipleChoiceAnswers(values as ResponseValue<string>[], responseBase, variant);
      }
      return []; // needed for no-fallthrough lint rule
    }
    // multiple choice, dropdown, & checkbox (& possibly single/many image choice, may need refinement)
    case 'MULTIPLE_CHOICE': {
      // same logic for all variants:
      // SINGLE_ANSWER_RADIO, SINGLE_ANSWER_MENU, MANY_ANSWERS_CHECKBOX, SINGLE_ANSWER_IMAGE, MANY_ANSWERS_IMAGE;
      return mapMultipleChoiceAnswers(values as ResponseValue<string>[], responseBase);
    }
    // presentational questions
    case 'PRESENTATION': {
      // same for Image and Text
      return [];
    }
    case 'RATING_SCALE': {
      switch (variant as QuestionTypeVariant['RATING_SCALE']) {
        case 'SINGLE_ANSWER_SYMBOL':
          return mapStarRatingAnswers(values as ResponseValue<string>[], responseBase);
        case 'NPS': {
          if (!values[0]?.value) {
            return [];
          }
          const response = [
            {
              ...responseBase,
              answerId: values[0].id,
              columnId: values[0].value as InputMaybe<string>,
            },
          ];
          return response;
        }

        case 'SINGLE_ANSWER_RADIO': {
          return (values as MatrixAnswerType['values']).reduce<Partial<RespApiAnswerInput>[]>((acc, row) => {
            const rowValue = row?.value as ResponseValue<string>[] | undefined;

            if (!row || !rowValue || !Array.isArray(rowValue)) {
              return acc;
            }
            if (rowValue[0].type) {
              return [...acc, { ...responseBase, answerId: row.id, answerText: rowValue[0].value }];
            }
            const response = rowValue.map(({ value }) => ({
              ...responseBase,
              answerId: row.id,
              columnId: value,
            }));

            return [...acc, ...response];
          }, []);
        }
        default: {
          // eslint-disable-next-line no-console
          console.warn('Rating scale variant not implemented: ', variant);
          return [];
        }
      }
    }

    // matrix
    case 'MATRIX': {
      switch (variant as QuestionTypeVariant['MATRIX']) {
        case 'MENU': {
          return (values as MatrixAnswerType['values']).reduce<Partial<RespApiAnswerInput>[]>((acc, row) => {
            const rowValue = row?.value as ResponseValue<string>[] | undefined;

            if (!row || !rowValue || !Array.isArray(rowValue)) {
              return acc;
            }
            // for comment per question
            if (rowValue[0].type) {
              return [...acc, { ...responseBase, answerId: row.id, answerText: rowValue[0].value }];
            }

            // menu needs ({ id, value }). id = columnId and value = menuId
            const response = rowValue.map(({ value, id }) => ({
              ...responseBase,
              answerId: row.id,
              columnId: id,
              menuId: value,
            }));

            return [...acc, ...response];
          }, []);
        }
        case 'MANY_ANSWERS_CHECKBOX':
        case 'SINGLE_ANSWER_RADIO': {
          return (values as MatrixAnswerType['values']).reduce<Partial<RespApiAnswerInput>[]>((acc, row) => {
            const rowValue = row?.value as ResponseValue<string>[] | undefined;

            if (!row || !rowValue || !Array.isArray(rowValue)) {
              return acc;
            }
            // for comment per question
            if (rowValue[0].type === 'COMMENT') {
              return [...acc, { ...responseBase, answerId: row.id, answerText: rowValue[0].value }];
            }
            // if for one comment per row
            if (rowValue[0].type === 'COMMENT_COLUMN' || rowValue[1]?.type === 'COMMENT_COLUMN') {
              // these are here to get the index value of the "answerText"
              const answerTextIndex = rowValue[0].type === 'COMMENT_COLUMN' ? 0 : 1;
              const columnIdIndex = answerTextIndex === 0 ? 1 : 0;
              // the back-end when receiving "answerText" and an "answerId" associated with row Id it interprets that as other option per-row.
              if (rowValue[columnIdIndex]) {
                return [
                  ...acc,
                  {
                    ...responseBase,
                    answerId: row.id,
                    answerText: rowValue[answerTextIndex].value,
                  },
                  {
                    ...responseBase,
                    answerId: row.id,
                    columnId: rowValue[columnIdIndex].value,
                  },
                ];
              }
              // this if is for if the user only fills out the other option and doesn't pass a radio selection
              if (!rowValue[columnIdIndex]) {
                return [
                  ...acc,
                  {
                    ...responseBase,
                    answerId: row.id,
                    answerText: rowValue[answerTextIndex].value,
                  },
                ];
              }
            }
            const response = rowValue.map(({ value }) => ({
              ...responseBase,
              answerId: row.id,
              columnId: value,
            }));

            return [...acc, ...response];
          }, []);
        }
        default: {
          // eslint-disable-next-line no-console
          console.warn('Matrix variant not implemented: ', variant);
          return [];
        }
      }
    }
    // date/time
    case 'DATETIME': {
      return mapDateTimeAnswers(
        values as ResponseValue<DateTimeAnswerPayload>[],
        responseBase,
        (externalQuestion as ExternalDateTime).validation?.type
      );
    }
    case 'SLIDER': {
      switch (variant as QuestionTypeVariant['SLIDER']) {
        case 'SINGLE_SLIDER': {
          if (!values[0]?.value) {
            return [];
          }
          const response = [
            {
              ...responseBase,
              questionId: values[0].id,
              answerText: values[0].value as InputMaybe<string>,
            },
          ];
          return response;
        }
        default: {
          // eslint-disable-next-line no-console
          console.warn('Slider variant not implemented: ', variant);
          return [];
        }
      }
    }
    case 'FILE_UPLOAD': {
      if (!values[0]?.value) {
        return [];
      }
      return [
        {
          ...responseBase,
          questionId: values[0].id,
          answerText: values[0].value as InputMaybe<string>,
        },
      ];
    }
    default: {
      // keep this warning while we integrate all Question Types
      // eslint-disable-next-line no-console
      console.warn('Question Type not mapped:', family, variant);
      return [];
    }
  }
};

type MapSurveyAnswersArgsType = {
  questions: ExternalQuestion[];
  answers: QuestionAnswers;
  pageId: string;
  isValid: boolean;
  isPrevious: boolean;
};

/**
 *
 * @param questions Array containing questions from current page
 * @param answers Object containing answers for the current page with questionId as the key
 * @param pageId Current survey page that user is responding to
 * @returns an array of responses matching the shape expected by mutation, empty responses discarded.
 */
export const mapSurveyAnswersToResponse = ({
  questions,
  answers,
  pageId,
  isValid,
  isPrevious,
}: MapSurveyAnswersArgsType): Partial<RespApiAnswerInput>[] => {
  if (!isValid && isPrevious) {
    return [];
  }
  return Object.values(answers).reduce<Partial<RespApiAnswerInput>[]>((responses, answer) => {
    /** The base response object containing properties to be included with each response */
    const responseBase: Partial<RespApiAnswerInput> = {
      pageId,
      questionId: answer.questionId,
    };
    // get the current question for the answer by matching questionId from answer
    const currentQuestion = questions.find(q => q.id === answer.questionId);
    if (!currentQuestion) {
      // this should not happen but just to safe log for debugging
      // eslint-disable-next-line no-console
      console.error(`could not find question for Answer`, answer);
      return responses;
    }

    const implicitResponse = mapImplicitResponse(currentQuestion);
    if (implicitResponse) {
      responseBase.randomAssignmentId = implicitResponse;
    }

    return [...responses, ...mapAnswerResponse(answer, currentQuestion, responseBase)];
  }, []);
};

export default mapSurveyAnswersToResponse;
