import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';
import { WrenchTheme } from '@wds/styles';

const useStyles = createSurveyStyles(theme => {
  return {
    selectContainer: {
      fontWeight: 400,
      fontFamily: WrenchTheme.type.fontFamily.base,
      fontSize: WrenchTheme.type.fontSize.body, // 16px
      color: theme.titleColor,
      borderRadius: 0,
      '& select': {
        backgroundColor: 'transparent',
        paddingRight: '1.5em', // 24px ((6px+6px+12px)÷16px)
        '&:focus-within, &:hover': {
          border: `1px solid ${theme.titleColor}`,
        },
      },
      '& option': {
        color: '#000',
      },
      '& svg': {
        width: `0.75em`, // 12px (12px÷16px)
        height: `0.75em`, // 12px (12px÷16px)
      },
    },
  };
});

export default useStyles;
